import { Checkbox } from 'antd';
import * as React from "react";
import './StyledCheckBox.styles.less';
import { StyledCheckBoxProps } from "./StyledCheckBox.types";

export const StyledCheckBoxComponent = (props:StyledCheckBoxProps) => {
    const {children, ...rest} = props;

    return (
        <Checkbox.Group className={'styled-checkbox-wrapper--checkbox'} {...rest}>
            {children}
        </Checkbox.Group>
    );
}