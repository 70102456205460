import * as React from "react";
import { Link } from 'react-router-dom';
import './ProductDropdownMenu.styles.less';
import { ICategoryNavColumn, ICategoryNavData, IProductDropdownMenuState, ProductDropdownMenuProps } from "./ProductDropdownMenu.types";

export const ProductDropdownMenuComponent = (props:ProductDropdownMenuProps) => {
    const [state, setState] = React.useState<IProductDropdownMenuState>({
        columns: undefined
    });

    // Used to apply a conditional style to allow for basic navigation on IE
    const isIE = !!document.documentMode;

    const getCategoryNavData = (categories: any):ICategoryNavData[] => 
        categories.filter((item:any) => item.tag.toLowerCase() !== 'hidden').map( (item:any) => ({
            categories: item.categories ? getCategoryNavData(item.categories) : undefined,
            categoryId: item.categoryId,
            categoryName: item.categoryName
        }) );

    React.useEffect( () => {
        setState({...state, 
            columns: [{
                categoryData: getCategoryNavData(props.data),
                selectedIndex: undefined
            }]
        });
    }, [props.data, props.location]);

    const handleOnClick = (categories: ICategoryNavData[], columnIndex: number, rowIndex: number) => (e: any) => {
        e.preventDefault();

        let data: ICategoryNavColumn[];

        // Remove unneeded columns
        if ( state.columns!.length > columnIndex + 1) {
            data = state.columns!.slice(0, columnIndex + 1)
        } else {
            data = state.columns!;
        }

        // Set selected index
        data[columnIndex].selectedIndex = rowIndex;

        // Add new column
        data.push({
            categoryData: categories,
            selectedIndex: undefined
        });

        setState({columns: data});
    }
    
    return (
        <div className={'product-dropdown-menu'} style={isIE ? {width: '50vw'} : undefined}>
        { state.columns && state.columns.map( (column, columnIndex) => 
            <ul key={`column-${columnIndex}`}>
            {column.categoryData.map( (item, index) => 
                <li key={item.categoryId}>
                    <Link 
                        onClick={ item.categories ? handleOnClick(item.categories, columnIndex, index) : undefined }
                        to={`/category/${item.categoryId}`} 
                        dangerouslySetInnerHTML={{__html: item.categoryName}} 
                        className={index === column.selectedIndex ? 'selected-category' : undefined}
                    /> 
                </li>
            )}
            </ul>
        )}
        </div>
    );
}
    