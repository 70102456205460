import { connect  } from "react-redux";
import { OnlineDealerItemComponent  } from "./OnlineDealerItem.component";
import { IOnlineDealerItemDispatchProps, IOnlineDealerItemProps, IOnlineDealerItemStateProps, OnlineDealerItemProps } from "./OnlineDealerItem.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IOnlineDealerItemProps):IOnlineDealerItemStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IOnlineDealerItemProps):IOnlineDealerItemDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IOnlineDealerItemStateProps, dispatch:IOnlineDealerItemDispatchProps, props:IOnlineDealerItemProps):OnlineDealerItemProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const OnlineDealerItem = connect<IOnlineDealerItemStateProps, IOnlineDealerItemDispatchProps, IOnlineDealerItemProps, OnlineDealerItemProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(OnlineDealerItemComponent);