import { mapTheme } from './DealerLocatorMap.types';
import { aubergine, dark, night, retro, silver, standard } from './MapTheme.config';

export function getMapTheme(theme?:mapTheme):any {
    let output;
    
    switch (theme) {
        case mapTheme.Silver:
            output = silver;
            break;
        case mapTheme.Retro:
            output = retro;
            break;
        case mapTheme.Dark:
            output = dark;
            break;
        case mapTheme.Night:
            output = night;
            break;
        case mapTheme.Aubergine:
            output = aubergine;
            break;
        default:
        case mapTheme.Standard:
            output = standard;
            break;
    }

    return output;
}