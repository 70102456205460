import { IDealerData } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/OnlineDealer/onlineDealer.types';
import loadjs from 'loadjs';
import * as React from "react";
import { getMapTheme } from './DealerLocatorMap.helpers';
import './DealerLocatorMap.styles.less';
import { DealerLocatorMapProps } from "./DealerLocatorMap.types";

export const DealerLocatorMapComponent = (props:DealerLocatorMapProps) => {
    const [state, setState] = React.useState({
        activeMarkers: [] as google.maps.Marker[],
        dealerList: props.dealerList,
        dots: [] as google.maps.Marker[],
        infoWindows: [] as google.maps.InfoWindow[],
        isLoading: true,
        map: undefined as google.maps.Map | undefined,
        offPageDealerList: props.offPageDealerList
    });

    const infoWindows = [] as google.maps.InfoWindow[];
    const googleMapScriptUri = `https://maps.googleapis.com/maps/api/js?key=${props.apiKey}`;
    
    // Image URIs
    const markerDir = props.markerDir ? props.markerDir : '//assets-static.lemansnet.com/common/img/dealerLocator/default_markers/nonFeatured/';
    const featuredMarkerDir = props.featuredMarkerDir ? props.featuredMarkerDir : '//assets-static.lemansnet.com/common/img/dealerLocator/default_markers/blue/';
    const dotUri = props.dotUri ? props.dotUri : '//assets-static.lemansnet.com/common/img/dealerLocator/default_markers/nonFeatured/nonfeatured_notOnPage.png';
    const featuredDotUri = props.featuredDotUri ? props.featuredDotUri : '//assets-static.lemansnet.com/common/img/dealerLocator/default_markers/blue/featured_notOnPage.png';

    React.useEffect( () => {
        if (state.isLoading) {
            if (!loadjs.isDefined('gmap')) {
                loadjs([googleMapScriptUri], 'gmap');
                loadjs.ready('gmap', () => setState({...state, isLoading: false}));
            } else {
                setState({...state, isLoading: false});
            }
        }
        else {
            setTimeout(() => {
                const defaultParams = {
                    center: {
                        lat: props.defaultLat ? props.defaultLat : 39.8283, 
                        lng: props.defaultLng ? props.defaultLng : -98.5795
                    },
                    mapTypeControl: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: getMapTheme(props.theme),
                    zoom: 5,
                    zoomControl: true,
                };
    
                const initMap = new google.maps.Map(document.getElementById('ldl-map') as HTMLElement, defaultParams);
                setState({...state, map: initMap});
            }, 1000);
            // const defaultParams = {
            //     center: {
            //         lat: props.defaultLat ? props.defaultLat : 39.8283, 
            //         lng: props.defaultLng ? props.defaultLng : -98.5795
            //     },
            //     mapTypeControl: true,
            //     mapTypeId: google.maps.MapTypeId.ROADMAP,
            //     styles: getMapTheme(props.theme),
            //     zoom: 5,
            //     zoomControl: true,
            // };

            // const initMap = new google.maps.Map(document.getElementById('ldl-map') as HTMLElement, defaultParams);
            // setState({...state, map: initMap});
        }
    }, [state.isLoading]);

    const clearMap = () => {
        state.infoWindows.forEach( window => window.close() );
        state.activeMarkers.forEach( marker => marker.setMap(null));
        state.dots.forEach( dot => dot.setMap(null));
    }

    const getMarkerIconUri = (dealer:IDealerData, index:number) => {
        const markerBase = dealer.featureNonFeature === 'F' ? featuredMarkerDir : markerDir;
        let output;

        if (dealer.featureNonFeature === 'F' && props.featuredMarkerImageName) {
            output = `${markerBase}${props.featuredMarkerImageName}`;
        } else {
            output = `${markerBase}marker${index + 1}.png`;
        }

        return output;
    }

    const makeMarkers = (windows:google.maps.InfoWindow[]) => {
        const output = [] as google.maps.Marker[];

        if (state.map && props.dealerList) {        
            props.dealerList.sort( (a, b) => a.distance - b.distance ).forEach( (dealer, index) => {
                
                const marker = new google.maps.Marker({
                    animation: google.maps.Animation.DROP,
                    icon: getMarkerIconUri(dealer, index),
                    map: state.map,
                    position: {lat: dealer.latitude, lng: dealer.longitude},
                    title: dealer.dealerName
                });

                output.push(marker);
                windows.push(makeInfoWindow(dealer, marker));

                if (index === 0 && state.map) {
                    state.map.setCenter( marker.getPosition() as google.maps.LatLng );
                    state.map.setZoom(8);
                }
            });
        }

        return output;
    };

    const makeDots = (windows:google.maps.InfoWindow[]) => {
        const output = [] as google.maps.Marker[];

        if (state.map && props.offPageDealerList) {
            props.offPageDealerList.sort( (a,b) => a.distance - b.distance ).forEach( dealer => {
                const dotImage = dealer.featureNonFeature === 'F' ? featuredDotUri : dotUri;

                const marker = new google.maps.Marker({
                    animation: google.maps.Animation.DROP,
                    icon: dotImage,
                    position: {lat: dealer.latitude, lng: dealer.longitude},
                    title: dealer.dealerName
                });

                output.push(marker);
                windows.push(makeInfoWindow(dealer, marker));

                if (state.map) {
                    marker.setMap(state.map);
                }
                
            });
        }
        
        return output;
    };

    const buildInfoWindowContent = (dealer: IDealerData) => {
        const output = `
        <div class='ldl-info-window-wrapper--div'>
            <h3>${dealer.dealerName}</h3>
            <div class='ldl-info-window-address--div'>
                <div>${dealer.dealerAddressLine1}${dealer.dealerAddressLine2 ? `, ${dealer.dealerAddressLine2}` : ``}<div>
                <div>${dealer.dealerAddressCity}, ${dealer.dealerAddressCountry === "US" ? `${dealer.dealerAddressState} ${dealer.dealerAddressZipCode} - `: ''}${dealer.dealerAddressCountry}</div>
            </div>
            <div class='ldl-info-window-phone--div'>ph: ${dealer.dealerPhoneNumber} - ${dealer.distance} mi</div>
        </div>
        `

        return output;
    }

    const closeInfoWindows = () => { 
        infoWindows.forEach( window => window.close() ) 
    }

    const makeInfoWindow = (dealer: IDealerData, marker:google.maps.Marker) => {
        const infoWindow = new google.maps.InfoWindow({
            content: buildInfoWindowContent(dealer)
        });

        if (state.map) {
            marker.addListener('click', () => {
                closeInfoWindows();
                infoWindow.open(state.map, marker); 
            });
        }

        return infoWindow;
    };

    // Apply features to the map
    if (props.dealerList !== state.dealerList || props.offPageDealerList !== state.offPageDealerList) {
        clearMap();

        const activeMarkers = makeMarkers(infoWindows);
        const dots = makeDots(infoWindows);
    
        setState({
            ...state, 
            activeMarkers, 
            dealerList: props.dealerList, 
            dots, 
            infoWindows,
            offPageDealerList: props.offPageDealerList
        });
    }
    
    // If a dealer is selected center the map on that dealer and open its info window
    if (state.map && props.selectedDealerIndex !== undefined && state.activeMarkers[props.selectedDealerIndex] && state.infoWindows.length) {
        state.infoWindows.map( window => window.close() );
        const marker = state.activeMarkers[props.selectedDealerIndex];

        state.map.setZoom(13);
        state.map.panTo(marker.getPosition() as google.maps.LatLng);

        state.infoWindows[props.selectedDealerIndex].open(state.map, marker);
    }

    return (
        <div id={'ldl-map'}/>
    );
}
    
    