import { connect  } from "react-redux";
import { OnlineDealersComponent  } from "./OnlineDealers.component";
import { IOnlineDealersDispatchProps, IOnlineDealersProps, IOnlineDealersStateProps, OnlineDealersProps } from "./OnlineDealers.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IOnlineDealersProps):IOnlineDealersStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IOnlineDealersProps):IOnlineDealersDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IOnlineDealersStateProps, dispatch:IOnlineDealersDispatchProps, props:IOnlineDealersProps):OnlineDealersProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const OnlineDealers = connect<IOnlineDealersStateProps, IOnlineDealersDispatchProps, IOnlineDealersProps, OnlineDealersProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(OnlineDealersComponent);