import { connect  } from "react-redux";
import { SpinnerComponent  } from "./Spinner.component";
import { ISpinnerDispatchProps, ISpinnerProps, ISpinnerStateProps, SpinnerProps } from "./Spinner.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:ISpinnerProps):ISpinnerStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:ISpinnerProps):ISpinnerDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:ISpinnerStateProps, dispatch:ISpinnerDispatchProps, props:ISpinnerProps):SpinnerProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const Spinner = connect<ISpinnerStateProps, ISpinnerDispatchProps, ISpinnerProps, SpinnerProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(SpinnerComponent);