import { connect } from "react-redux";
import { FeaturedTireComponent } from "./FeaturedTire.component";
import { FeaturedTireProps, IFeaturedTireDispatchProps, IFeaturedTireProps, IFeaturedTireStateProps } from "./FeaturedTire.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IFeaturedTireProps):IFeaturedTireStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IFeaturedTireProps):IFeaturedTireDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IFeaturedTireStateProps, dispatch:IFeaturedTireDispatchProps, props:IFeaturedTireProps):FeaturedTireProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const FeaturedTire = connect<IFeaturedTireStateProps, IFeaturedTireDispatchProps, IFeaturedTireProps, FeaturedTireProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(FeaturedTireComponent);