import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { ProductMenuComponent } from "./ProductMenu.component";
import { IProductMenuDispatchProps, IProductMenuProps, IProductMenuStateProps, ProductMenuProps } from "./ProductMenu.types";


// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IProductMenuProps):IProductMenuStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IProductMenuProps):IProductMenuDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IProductMenuStateProps, dispatch:IProductMenuDispatchProps, props:IProductMenuProps):ProductMenuProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const ProductMenu = withRouter(connect<IProductMenuStateProps, IProductMenuDispatchProps, IProductMenuProps, ProductMenuProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ProductMenuComponent));