import { connect  } from "react-redux";
import { FourOhFourComponent  } from "./FourOhFour.component";
import { FourOhFourProps, IFourOhFourDispatchProps, IFourOhFourProps, IFourOhFourStateProps } from "./FourOhFour.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IFourOhFourProps):IFourOhFourStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IFourOhFourProps):IFourOhFourDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IFourOhFourStateProps, dispatch:IFourOhFourDispatchProps, props:IFourOhFourProps):FourOhFourProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const FourOhFour = connect<IFourOhFourStateProps, IFourOhFourDispatchProps, IFourOhFourProps, FourOhFourProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(FourOhFourComponent);