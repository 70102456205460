import { connect } from "react-redux";
import { LegalComponent } from "./Legal.component";
import { ILegalDispatchProps, ILegalProps, ILegalStateProps, LegalProps } from "./Legal.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:ILegalProps):ILegalStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:ILegalProps):ILegalDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:ILegalStateProps, dispatch:ILegalDispatchProps, props:ILegalProps):LegalProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const Legal = connect<ILegalStateProps, ILegalDispatchProps, ILegalProps, LegalProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(LegalComponent);