import { connect } from "react-redux";
import { AboutComponent } from "./About.component";
import { AboutProps, IAboutDispatchProps, IAboutProps, IAboutStateProps } from "./About.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IAboutProps):IAboutStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IAboutProps):IAboutDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IAboutStateProps, dispatch:IAboutDispatchProps, props:IAboutProps):AboutProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const About = connect<IAboutStateProps, IAboutDispatchProps, IAboutProps, AboutProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(AboutComponent);