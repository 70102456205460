import { Page } from 'lemans-brandsites-common/components//Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { getState } from 'lemans-brandsites-common/libs/getState';
import { IProductGroupResponseData, IProductResponseData } from 'lemans-brandsites-common/libs/Product/product.types';
import * as React from "react";
import { FourOhFour } from '../FourOhFour';
import { getProductData } from './ProductDetails.helper';
import './ProductDetails.styles.less';
import { ProductDetailsProps } from "./ProductDetails.types";
import { ProductSpecification } from './ProductSpecification';

export const ProductDetailsComponent = (props:ProductDetailsProps) => {
    const state = getState({
        hasError: false,
        isLoading: true, 
        productData: {} as IProductResponseData, 
        productGroupData: {} as IProductGroupResponseData,
        productGroupId: props.productGroupId
    });

    React.useEffect( () => {
        getProductData(props, state)
    }, [props.productId, props.productGroupId]);

    return (
        <>
        { state.isLoading.value &&
            <Spinner />
        }
        { state.hasError.value && 
            <FourOhFour />
        }
        { !state.isLoading.value  && !state.hasError.value &&
            <Page
                description={`AMS - BUILT FOR THE DEMANDING.`}
                robots={{follow: true, index: true}}
                title={`AMS Tires - ${state.productGroupData.value.productGroupName}`}
            >
                <ProductSpecification 
                    product={state.productData.value}
                    productGroup={state.productGroupData.value}
                />
            </Page>
        }
        </>
    );
}