import { Col, Row } from 'antd';
import { EmbeddedVideo } from 'lemans-brandsites-common/components/EmbeddedVideo';
import { ProductFeatures } from 'lemans-brandsites-common/components/ProductFeatures';
import * as React from "react";
import { PartChart } from 'theme/components/PartChart';
import { ProductImage } from 'theme/components/ProductImage';
import { RelatedProducts } from 'theme/components/RelatedProducts';
import './ProductSpecification.styles.less';
import { ProductSpecificationProps } from "./ProductSpecification.types";

export const ProductSpecification = (props:ProductSpecificationProps) => {
    const video = props.product && props.product.media && 
        props.product.media.filter( (item) => item.mediaTypeDescription === "video")[0];

    const hasAccessories = props.product && props.product.relatedEntity && props.product.relatedEntity.filter(res => res.relatedClass === "ProductGroup") ? true : false;
    const hasSuggestedItems = props.product && props.product.relatedEntity && props.product.relatedEntity.filter(res => res.relatedClass === "product") ? true : false;

    return (
        <div className={'product-specification-wrapper'}>
            <Row justify="space-around" align="middle" className="prod_name_wrapper">
                <Col xs={24} sm={24} md={0}>
                    <ProductImage 
                        productId={props.product.productId}
                        selectedGroupMediaData={props.productGroup.media
                            .filter( item => item.entityClass === "ProductGroup" 
                                && item.mediaTypeDescription === "image")}
                        productGroupData={props.product.productGroups.length > 1 ? 
                            props.product.productGroups
                            : undefined
                        }
                    />
                </Col>
                <Col xs={0} sm={0} md={12} offset={12} >
                    <h2>{props.productGroup.entityName}</h2>
                </Col>
                <Col xs={24} sm={24} md={0}>
                    <h2>{props.productGroup.entityName}</h2>
                </Col>
            </Row>
            <Row justify="center" align="top">
                <Col xs={0} sm={0} md={10} md-offset={2}>
                    <ProductImage 
                        productId={props.product.productId}
                        selectedGroupMediaData={props.productGroup.media
                            .filter( item => item.entityClass === "ProductGroup" 
                                && item.mediaTypeDescription === "image")}
                        productGroupData={props.product.productGroups.length > 1 ? 
                            props.product.productGroups
                            : undefined
                        }
                    />
                </Col>
                <Col xs={24} sm={24} md={10}>
                    <ProductFeatures data={props.product.features} />
                </Col>
            </Row>
            {props.productGroup.chart &&
            <Row justify="space-around" align="middle">
                <Col xs={20}>
                    <PartChart 
                        key={'0'} 
                        columns={props.productGroup.chart[0].header[0].columns} 
                        data={props.productGroup.chart[0].rows} 
                    />
                </Col>                
            </Row>
            }
            {hasAccessories && props.product.relatedEntity && 
            <>
            <Row justify="space-around" align="middle">
                <Col xs={20}>
                    <h3>ACCESSORIES for your {props.productGroup.entityName} </h3>
                </Col>
            </Row>
            <Row justify="space-around" align="middle">
                <Col xs={20}>
                    <RelatedProducts data={props.product.relatedEntity.filter(res => res.relatedClass === "ProductGroup")}/>
                </Col>
            </Row>
            </>
            }
            { video &&
                <Row justify="space-around" align="middle">
                    <Col xs={20}>
                        <EmbeddedVideo mediaUrl={video.mediaUrl}/>
                    </Col>
                </Row>                
            }
            {hasSuggestedItems && props.product.relatedEntity && 
            <>
            <Row justify="space-around" align="middle">
                <Col xs={20}>
                    <h3>SUGGESTIONS for your {props.productGroup.entityName} </h3>
                </Col>
            </Row>
            <Row justify="space-around" align="middle">
                <Col xs={20}>
                    <RelatedProducts data={props.product.relatedEntity.filter(res => res.relatedClass === "Product")}/>
                </Col>
            </Row>
            </>
            }
        </div>
    );
}