import { api } from '../api';
import { IResponseData } from './page.types';


export const page = async (slug:string) => {
    const res = await getPage(slug);

    return res.body[0];
} 

const getPage = (slug:string):IResponseData => 
    api.get(`wp/v2/pages`).query({slug});