import { connect  } from "react-redux";
import { DealerLocatorInputGroupComponent  } from "./DealerLocatorInputGroup.component";
import { DealerLocatorInputGroupProps, IDealerLocatorInputGroupDispatchProps, IDealerLocatorInputGroupProps, IDealerLocatorInputGroupStateProps } from "./DealerLocatorInputGroup.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IDealerLocatorInputGroupProps):IDealerLocatorInputGroupStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IDealerLocatorInputGroupProps):IDealerLocatorInputGroupDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IDealerLocatorInputGroupStateProps, dispatch:IDealerLocatorInputGroupDispatchProps, props:IDealerLocatorInputGroupProps):DealerLocatorInputGroupProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const DealerLocatorInputGroup = connect<IDealerLocatorInputGroupStateProps, IDealerLocatorInputGroupDispatchProps, IDealerLocatorInputGroupProps, DealerLocatorInputGroupProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(DealerLocatorInputGroupComponent);