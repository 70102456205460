import { connect } from "react-redux";
import { ComponentDemoComponent } from "./ComponentDemo.component";
import { ComponentDemoProps, IComponentDemoDispatchProps, IComponentDemoProps, IComponentDemoStateProps } from "./ComponentDemo.types";

export const ComponentDemo = connect<IComponentDemoStateProps, IComponentDemoDispatchProps, IComponentDemoProps, ComponentDemoProps, any>(
    // The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
    (state:any, props:IComponentDemoProps):IComponentDemoStateProps => ({

    }),

    // The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
    (dispatch:any, props:IComponentDemoProps):IComponentDemoDispatchProps => ({
        dispatch,
    }),

    // The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
    (stateProps:IComponentDemoStateProps, dispatchProps:IComponentDemoDispatchProps, ownProps:IComponentDemoProps):ComponentDemoProps => ({
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    })
)(ComponentDemoComponent);