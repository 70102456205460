import { connect } from "react-redux";
import { ProductListingComponent } from "./ProductListing.component";
import { IProductListingDispatchProps, IProductListingProps, IProductListingStateProps, ProductListingProps } from "./ProductListing.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IProductListingProps):IProductListingStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IProductListingProps):IProductListingDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IProductListingStateProps, dispatch:IProductListingDispatchProps, props:IProductListingProps):ProductListingProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const ProductListing = connect<IProductListingStateProps, IProductListingDispatchProps, IProductListingProps, ProductListingProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ProductListingComponent);