import { Col, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import { IPageResponseData } from 'lemans-brandsites-common/libs/GetPageFromWP/page.types';
import * as React from "react";
import './Legal.styles.less';
import { LegalProps } from "./Legal.types";

export const LegalComponent = (props:LegalProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [pageData, setPageData] = React.useState<IPageResponseData | undefined>(undefined);
    const [trademarkData, setTrademarkData] = React.useState<IPageResponseData | undefined>();
    const [termsData, setTermsData] = React.useState<IPageResponseData | undefined>();

    const getData = async () => {
        setIsLoading(true);
        try {
            const [pageRes, trademarkRes, termsRes] = await Promise.all([page('legal'), page('trademark-attribution'), page('terms-and-conditions')])
            setPageData(pageRes);
            setTrademarkData(trademarkRes);
            setTermsData(termsRes);
        }
        finally {
            setIsLoading(false);
        }
    }

    const bannerComponent = () => {
        let output = <img />;

        if (pageData && pageData.acf.banner_image) {
            output = <div className="pageBanner"><img src={`${apiConfig.host}${pageData.acf.banner_image}`} /></div>
        }
        
        return output;
    };

    React.useEffect( () => {
        getData()
    }, [])
    
    return (
        <>
        { isLoading &&
            <Spinner />
        }
        { !isLoading && 
            <Page
                description={'AMS - BUILT FOR THE DEMANDING.'}
                robots={{follow: true, index: true}}
                title={'AMS Tires - Legal'}
            >
                
                <>
                    {pageData &&
                        bannerComponent()
                    }
                    <Row 
                        align={'bottom'} 
                        justify="space-around"
                        className={'generic-page-wrapper--div'}
                    >
                        <Col xs={18} className={'generic-page-content--div'}>
                            <a href={'http://cpsc.lemansnet.com/'} target={'_blank'}><h2>Conformity Certificates</h2></a>
                            { termsData &&
                                <>
                                    <h2>{termsData.title.rendered}</h2>
                                    <div 
                                        className={'terms-content--div'}
                                        dangerouslySetInnerHTML={{__html: termsData.content.rendered}}
                                    />
                                </>
                            }
                            { trademarkData &&
                                <>
                                    <h2>{trademarkData.title.rendered}</h2>    
                                    <div 
                                        className={'trademark-content--div'}
                                        dangerouslySetInnerHTML={{__html: trademarkData.content.rendered}}
                                    />
                                </>
                            }
                        </Col>
                    </Row>
                </>
            </Page>
        }
        </>
    );
}