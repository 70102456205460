import { product } from 'lemans-brandsites-common/libs/Product';
import { IProductResponseData } from 'lemans-brandsites-common/libs/Product/product.types';
import { ProductDetailsProps } from "./ProductDetails.types";

/** Gets product data from API if it doesn't already exist and assigns to state object */
export async function getProductData(props:ProductDetailsProps, state:any) {
    try {
        state.isLoading.set(true);
        let data;
        if ( haveDataForProduct(props.productId, state) ) {
            data = state.productData.value as IProductResponseData;
        } else {
            data = await product(props.productId);
        }
        
        setStateData(props.productGroupId, state, data);
    }
    catch {
        state.hasError.set(true);
    }
    finally {
        state.isLoading.set(false);
    }
}

/** Sets product and product group data to appropriate state properties */
function setStateData(productGroupId:number | undefined, state:any, data:IProductResponseData) {
    state.productData.set(data);

    if (!productGroupId) {
        state.productGroupId.set(data.productGroups[0].productGroupId);
        state.productGroupData.set(data.productGroups[0]);
    } 
    else if ( haveUpdatedProductGroup(productGroupId, state) ) {
        const productGroupData = getProductGroupById(productGroupId, data);

        if (productGroupData) {
            state.productGroupData.set(productGroupData);
            state.productGroupId.set(productGroupId); 
        } else {
            state.hasError.set(true);
        }    
    }
    else {
        state.productGroupData.set(data.productGroups.filter( item => item.productGroupId.toString() === state.productGroupId.value.toString())[0]);
    }
}

/** Indicates is data for the product already has been obtained */
function haveDataForProduct(productId:number, state:any) {
    return state.productData.value.productId && state.productData.value.productId.toString() === productId.toString();
}

/** Indicates if product group ID has been changed */
function haveUpdatedProductGroup(productGroupId:number, state:any) {
    return state.productGroupId.toString() !== productGroupId.toString();
}

/** Gets the first product group data that matches the productGroupId */
function getProductGroupById(productGroupId:number, data:IProductResponseData) {
    return data.productGroups.filter( item => 
        item.productGroupId.toString() === productGroupId.toString())[0];
}