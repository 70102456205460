import { Col, Row } from 'antd';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import * as React from 'react';
import './GenericStaticPage.styles.less';
import { GenericStaticPageProps } from './GenericStaticPage.types';

export const GenericStaticPageComponent = (props:GenericStaticPageProps) => {
    const pageContent = () => {
        let output = <></>;

        if (props.data) {
            output = 
                <div 
                    className={'generic-page-content--div'}
                    dangerouslySetInnerHTML={{__html: props.data.content.rendered}}
                />
        }

        return output;
    }

    const bannerComponent = () => {
        let output = <img />;

        if (props.data.acf.banner_image) {
            output = <div className="pageBanner"><img src={`${apiConfig.host}${props.data.acf.banner_image}`} /></div>
        }
        
        return output;
    };

    return (
        <>
            {bannerComponent()}
            <Row 
                align={'bottom'} 
                justify="space-around"
                className={'generic-page-wrapper--div'}
            >
                <Col xs={18}>{pageContent()}</Col>
            </Row>
            
        </>
    )
}
   
    