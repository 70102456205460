import { Carousel } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { ResultGrid } from 'lemans-brandsites-common/components/ResultGrid';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { TilesGrid } from 'lemans-brandsites-common/components/TilesGrid';
import { api } from 'lemans-brandsites-common/libs/api';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import * as React from 'react';
import './HomePage.style.less';
import { IHomeCarouselGraphics, IHomePageGraphics } from './HomePage.types';

export const HomePage = () : JSX.Element => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [homePageGraphics, setHomePageGraphics] = React.useState<IHomePageGraphics | undefined>();

    const getHomePageGraphics = async () => {
        setIsLoading(true);
        try {
            const data = await api.get(`wp/v2/pages`).query({slug: 'home'});
            setHomePageGraphics(data.body[0].acf);
        }
        catch {
            // Do something?
        }
        finally {
            setIsLoading(false);
        }
    }

    const getFeaturedItems = (data: any):any => data.map( (item:any) => {
        return {
            imgSrc: `${apiConfig.host}` + item.featured_tire_image + `?o=3dshadow`,
            imgTitle: item.featured_image_title, 
            itemTitle: item.featured_image_title,
            key: item.featured_image_title,
            linkUrl: item.featured_image_url
        }
    });

    const getTileItems = (data: any):any => data.map( (item:any) => {
        return {
            imgSrc: `${apiConfig.host}` + item.imgSrc,
            key: item.linkTitle,
            linkTitle: item.linkTitle,
            linkUrl: item.linkUrl
        }
    });

    React.useEffect( () => {
        getHomePageGraphics();        
    }, []);
    
    return (  
        <> 
            { isLoading &&       
                <Spinner />
            }
            { !isLoading && homePageGraphics &&
                <Page
                    description={'AMS - BUILT FOR THE DEMANDING.'}
                    robots={ {index: true, follow: true} }
                    title={'AMS Tires'}
                >
                    {homePageGraphics.home_carousel &&
                        <Carousel autoplay  
                            dots={homePageGraphics.home_carousel.length > 1}
                            className={'home-carousel'}
                        >
                        {homePageGraphics.home_carousel.map((carouselElement:IHomeCarouselGraphics) => 
                                <img 
                                    key={`img-${carouselElement.carousel_image}`} 
                                    src={`${apiConfig.host}` + carouselElement.carousel_image}
                                />
                            )
                        }
                        </Carousel>
                    }
                    {homePageGraphics.home_featured_tires &&
                        <div className="home_featured_wrapper--div">
                            <ResultGrid items={getFeaturedItems(homePageGraphics.home_featured_tires)} xlSize={6} lgSize={6} mdSize={6} smSize={12} xsSize={12}/>
                        </div>
                    }
                    {homePageGraphics.home_page_tiles &&
                        <div className="home_tiles_wrapper--div">
                            <TilesGrid items={getTileItems(homePageGraphics.home_page_tiles)} xlSize={12} lgSize={12} mdSize={12} smSize={24} xsSize={24}/>
                        </div>
                    }
                </Page>
            }
        </>          
    );
}