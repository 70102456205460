import { DealerLocatorInputParams } from 'lemans-brandsites-common/components/DealerLocatorInputGroup/DealerLocatorInputGroup.types';
import { ldlApi } from '../Api';
import { IDealerResponse } from '../OnlineDealer/onlineDealer.types';

export const dealer = async (apiConfig:any, params:DealerLocatorInputParams) => {
    const res = await getDealer(apiConfig, params);

    return res.body;
}

const getDealer = (apiConfig: any, params:DealerLocatorInputParams):IDealerResponse => {
    const paramCopy = {...params};
    delete paramCopy.country;

    // Remove any undefined parameters
    Object.keys(paramCopy).forEach(key => paramCopy[key] === undefined && delete paramCopy[key]);

    return ldlApi(apiConfig).get(`country/${params.country}/dealer`).query({...paramCopy});
}
    