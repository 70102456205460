import * as React from "react";
import { getEmbeddedVideoUrl } from './EmbeddedVideo.helper';
import './EmbeddedVideo.styles.less';
import { EmbeddedVideoProps } from "./EmbeddedVideo.types";

export const EmbeddedVideoComponent = (props:EmbeddedVideoProps) =>
    <div className={'embedded-video-wrapper--div'}>
        <iframe 
            src={getEmbeddedVideoUrl(props)}
            frameBorder={0}
            allowFullScreen
            allow={props.autoplay ? 'autoplay' : undefined}
        />
    </div>
    