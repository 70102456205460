import { merge } from 'atp-pointfree';
import request from 'superagent';

const restBase = '/wp-admin/admin-ajax.php/';
const restUrl = (apiConfig:any, url:string) => `${apiConfig.host}${restBase}${url}`;

export const ldlApi = (apiConfig:any) => ({
    get: (url:string) => request
        .get(restUrl(apiConfig, url))
        .set('Accept', 'application/json')
        .query({action: 'ldl_proxy', format: 'json', type: 'br'}),
    parseQuery: (query:{}) => Object.keys(query).map((key:string) => ({
        [key]: Array.isArray(query[key]) ? query[key].join(",") : query[key]
    })).reduce(merge)
});
