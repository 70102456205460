import { Col, Row } from 'antd';
import { productNav } from 'lemans-brandsites-common/libs/ProductNav';
import * as React from "react";
import { Link } from 'react-router-dom';
import { default as brandLogo } from '../../media/images/ams_header_logo.png';
import './Footer.styles.less';
import { FooterProps } from "./Footer.types";

export const FooterComponent = (props:FooterProps) => {
    const [state, setState] = React.useState({
        productData: undefined as any
    });

    const getData = async () => {
        try {
            const data = await productNav();
            setState({...state, productData: data});
        }
        catch {
            // Something
        }
    }

    React.useEffect( () => {
        getData();
    }, []);

    return (
        <>
        <Row justify="space-around" align="middle" id="footerRow">
            <Col sm={6} xs={0} md={6} lg={6} xl={6}>
                <img className="logo"src={brandLogo}/>
            </Col>
            <Col sm={18} xs={24} md={18} lg={18} xl={18}>
                <ul className="footerLinks">
                    { state.productData && 
                        state.productData.categories.filter((item:any) => item.tag.toLowerCase() !== 'hide_in_footer').map( (category:any) => {
                            return <li key={category.categoryId}><Link 
                                to={`/category/${category.categoryId}`} 
                                key={category.categoryId}
                                dangerouslySetInnerHTML={{__html: category.categoryName}} 
                            /></li>
                        })
                    }
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    {/* <li><Link to="/tire-safety">Tire Safety</Link></li>
                    <li><Link to="/tires-101">Tires 101</Link></li> */}
                    <li><Link to="/featured-tire">Featured Tire</Link></li>
                    <li><Link to="/wheel-maintenance">Wheel Maintenance</Link></li> 
                    <li><Link to="/legal">Legal</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                </ul>
            </Col>
        </Row>
        <Row justify="space-around" align="middle" id="copyRightRow">
             <Col sm={24} xs={24} md={24}>
                © {new Date().getFullYear()} LeMans Corporation. All Rights Reserved.
            </Col>
        </Row>
        </>
    );
}

    