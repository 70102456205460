import { prodTemplate } from 'theme/libs/config';
import { api } from '../api';
import { feedParameters } from '../FeedParameters';
import { IProductResponseData, IResponseData } from './product.types';

export const product = async (productId:number, template?:string) => {
    const feedParams = await feedParameters();
    const res = await getProduct(productId, feedParams, template);
    const body = res.body.root[0].domain[0].semantic.filter( semData => semData.semanticName === 'body')[0];
    let data = {} as IProductResponseData;

    if (body.products) {
        data =  body.products[0];
    }
    return data;
} 

const getProduct = (productId:number, feedParams:any, template?:string):IResponseData => 
    api.get('lemans/api/feed-info/masterFeed')
        .query(feedParams)
        .query({
            locale: 'en',
            productId,
            templateName: template ? template : prodTemplate
        });
    
