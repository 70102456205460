// import { Form } from 'antd';
import { connect } from "react-redux";
import { HelpCenterFormComponent } from "./HelpCenterForm.component";
import { HelpCenterFormProps, IHelpCenterFormDispatchProps, IHelpCenterFormProps, IHelpCenterFormStateProps } from "./HelpCenterForm.types";


// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IHelpCenterFormProps):IHelpCenterFormStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IHelpCenterFormProps):IHelpCenterFormDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IHelpCenterFormStateProps, dispatch:IHelpCenterFormDispatchProps, props:IHelpCenterFormProps):HelpCenterFormProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const HelpCenterForm = (connect<IHelpCenterFormStateProps, IHelpCenterFormDispatchProps, IHelpCenterFormProps, HelpCenterFormProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(HelpCenterFormComponent));