import { ldlApi } from '../Api';
import { IDealerResponse } from './onlineDealer.types';


export const onlineDealer = async (apiConfig:any) => {
    const res = await getOnlineDealer(apiConfig);

    return res.body;
}

const getOnlineDealer = (apiConfig: any):IDealerResponse => 
    ldlApi(apiConfig).get('dealer').query({type: 'web'})