import { connect  } from "react-redux";
import { SearchFacetFiltersComponent  } from "./SearchFacetFilters.component";
import { ISearchFacetFiltersDispatchProps, ISearchFacetFiltersProps, ISearchFacetFiltersStateProps, SearchFacetFiltersProps } from "./SearchFacetFilters.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:ISearchFacetFiltersProps):ISearchFacetFiltersStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:ISearchFacetFiltersProps):ISearchFacetFiltersDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:ISearchFacetFiltersStateProps, dispatch:ISearchFacetFiltersDispatchProps, props:ISearchFacetFiltersProps):SearchFacetFiltersProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const SearchFacetFilters = connect<ISearchFacetFiltersStateProps, ISearchFacetFiltersDispatchProps, ISearchFacetFiltersProps, SearchFacetFiltersProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(SearchFacetFiltersComponent);