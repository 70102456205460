import * as React from "react";
import { FeatureList } from '../FeatureList';
import { SubFeatureListProps } from './SubFeatureList.types';

export const SubFeatureList = (props: SubFeatureListProps) => 
    <div className={'product-features-subfeature--div'}>
        {props.data.map( item => 
        item.features && 
            <div key={item.sequence}>
                <p>{item.feature}</p>
                <FeatureList data={item.features} />
            </div>            
        )}
    </div>