import { Col } from 'antd';
import { imageUrl } from 'lemans-brandsites-common/libs/imageUrl';
import { productIdFromProductGroup } from 'lemans-brandsites-common/libs/ProductGroup';
import * as React from 'react';
import { Link } from 'react-router-dom';
import './RelatedProductItem.styles.less';
import { RelatedProductItemProps } from './RelatedProductItem.types';

export const RelatedProductItemComponent = (props:RelatedProductItemProps) => {
    const [relatedProdId, setRelatedProductId] = React.useState<any>();

    React.useEffect( () => {
        if(props.data.relatedClass.toLowerCase() === "productgroup") { 
            getRelatedProdId(props.data.relatedId).then((res:any)=> setRelatedProductId(res));
        } else {
            setRelatedProductId(props.data.relatedClass.toLowerCase() === "product" ? props.data.relatedId : undefined);
        }
    }, [props.data.relatedId])

    const getRelatedProdId = async(prodGrpId:any) => await productIdFromProductGroup(prodGrpId);

    const imgParams = {
        extension: props.data.extension,
        mediaUrl: props.data.mediaUrl, 
        prefixUrl: props.data.prefixUrl,
    }; 
    const imgSrc = imageUrl(imgParams, {x:400, t: 'image/jpeg', o:'3dshadow'});
    const productGrp = props.data.relatedClass.toLowerCase() === "productgroup" ? props.data.relatedId : undefined;
    const productGroupUrl = productGrp ? `/productGroup/${productGrp}` : ``;
    const linkUrl = `/products/${relatedProdId}` + productGroupUrl;

    return <Col xs={8} key={props.key} className="resultGridCol">
        <Link
            to={linkUrl} 
            title={props.data.relatedName}
        >
            <img src={imgSrc}/>
        </Link>
        <div className="result-grid-title">{props.data.relatedName}</div>
    </Col> 
}   