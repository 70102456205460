import { connect  } from "react-redux";
import { ResultGridComponent  } from "./ResultGrid.component";
import { IResultGridDispatchProps, IResultGridProps, IResultGridStateProps, ResultGridProps } from "./ResultGrid.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IResultGridProps):IResultGridStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IResultGridProps):IResultGridDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IResultGridStateProps, dispatch:IResultGridDispatchProps, props:IResultGridProps):ResultGridProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const ResultGrid = connect<IResultGridStateProps, IResultGridDispatchProps, IResultGridProps, ResultGridProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ResultGridComponent);