import { connect  } from "react-redux";
import { DealerListItemComponent  } from "./DealerListItem.component";
import { DealerListItemProps, IDealerListItemDispatchProps, IDealerListItemProps, IDealerListItemStateProps } from "./DealerListItem.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IDealerListItemProps):IDealerListItemStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IDealerListItemProps):IDealerListItemDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IDealerListItemStateProps, dispatch:IDealerListItemDispatchProps, props:IDealerListItemProps):DealerListItemProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const DealerListItem = connect<IDealerListItemStateProps, IDealerListItemDispatchProps, IDealerListItemProps, DealerListItemProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(DealerListItemComponent);