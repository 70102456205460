import { connect  } from "react-redux";
import { VideoPageComponent  } from "./VideoPage.component";
import { IVideoPageDispatchProps, IVideoPageProps, IVideoPageStateProps, VideoPageProps } from "./VideoPage.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IVideoPageProps):IVideoPageStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IVideoPageProps):IVideoPageDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IVideoPageStateProps, dispatch:IVideoPageDispatchProps, props:IVideoPageProps):VideoPageProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const VideoPage = connect<IVideoPageStateProps, IVideoPageDispatchProps, IVideoPageProps, VideoPageProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(VideoPageComponent);