import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { api } from 'lemans-brandsites-common/libs/api';
import { getState } from 'lemans-brandsites-common/libs/getState';
import { solrParameters } from 'lemans-brandsites-common/libs/SolrParameters';
import * as React from "react";
import { SearchResults } from '../SearchResults';
import './Search.styles.less';
import { SearchProps } from "./Search.types";

export const SearchComponent = (props:SearchProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoadingSearchResults, setIsLoadingSearchResults] = React.useState(true);
    const state = getState({
        docType: 'productGroup',
        mmyResults: [] as any,
        queryParamToggle: false, // when to re render search - only re-render if the query params in the url changes
    });

    const getSearchResults = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const configParams = await solrParameters();
        const urlObj = {
            ci: configParams.catalogInstanceId,
            docType: state.docType.value,
            rows: 500,
        }

        queryParams.forEach((value, key) => {
            urlObj[key] = value;
        })

        if(queryParams.get('q')) {
            setIsLoadingSearchResults(true);
            setIsLoading(true);
            try {
                const data = await api.get(`lemans/api/solr-info/search`)
                    .query(
                        urlObj
                    );
                state.mmyResults.set(data.body);
            }
            catch {
                // something
            }
            finally {
                setIsLoadingSearchResults(false);
                setIsLoading(false);
            }
        } else {
            state.mmyResults.set([]);
        }
    }

    // useEffect for solr search - re-render search on either when the state.queryParamToggle.value is set 
    // or when the page url is updated (2nd option is for making the narrow filters work) 
    React.useEffect( () => {
        getSearchResults();
    }, [state.queryParamToggle.value, props.location.search]);

    // determine weather to execute search
    const onSearch = () => {
        state.queryParamToggle.set(!state.queryParamToggle.value);
    }

    return(
        <>        
        { isLoading &&
            <Spinner />
        }
        {!isLoading && 
            <Page
                description={'AMS - BUILT FOR THE DEMANDING.'}
                robots={ {index: true, follow: true} }
                title={`AMS Tires - Search`}
            >
            { state.mmyResults.value &&
                <SearchResults mmyResults={state.mmyResults.value} isLoading={isLoadingSearchResults} onSearch={onSearch}/>
            }
            </Page>
        }
        </>
    );
}