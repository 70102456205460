import { Col, Row } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import './TilesGrid.styles.less';
import { TilesGridProps } from './TilesGrid.types';

export const TilesGridComponent = (props:TilesGridProps) =>
    <Row
        align={'bottom'} 
        className={'tiles-grid-wrapper' + (props.className ? ` ${props.className}` : '')}
    >                                
    { props.items && props.items.map( item => 
        <Col xl={props.xlSize} lg={props.lgSize} md={props.mdSize} sm={props.smSize} xs={props.xsSize} key={item.key}>
            <Link
                to={item.linkUrl} 
                title={item.linkTitle}
                onClick={props.onClickTile}
            >
                <img src={item.imgSrc}/>
            </Link>
        </Col>                  
    )}
    </Row>
    