import { Col, Collapse, Row, Table, Tabs } from 'antd';
import { as } from 'lemans-brandsites-common';
import * as React from 'react';
import { config, propTableColumns } from "./ComponentDemo.config";
import { ComponentDemoProps } from "./ComponentDemo.types";

export const ComponentDemoComponent = (props:ComponentDemoProps) =>
    <Row>
        <Col span={24}>
            <Tabs>
                {Object.keys(config).map((module:any) =>
                    <Tabs.TabPane key={module} tab={module}>
                        <Tabs tabPosition="left">
                            {Object.keys(config[module]).map((library:any) =>
                                <Tabs.TabPane key={library} tab={library}>
                                    <Collapse accordion>
                                        {Object.keys(config[module][library]).map((componentName:any) => as(config[module][library][componentName], (component:any) => 
                                            <Collapse.Panel key={component.name} header={component.name}>
                                            <Row>
                                                <Col span={24}>
                                                    <h2>Usage</h2>
                                                    <code>import {`{ ${component.name} }`} from '{component.location.split("src/").pop()}';</code>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <h2>Props</h2>
                                                    <Table pagination={false} dataSource={component.props} columns={propTableColumns} />
                                                </Col>
                                                <Col span={12}>
                                                    <h2>Description</h2>
                                                    <component.Description />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <h2>Examples</h2>
                                                    <component.Examples />
                                                </Col>
                                            </Row>                                            
                                        </Collapse.Panel> 
                                    ))}
                                    </Collapse>
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                    </Tabs.TabPane>
                )}
            </Tabs>
        </Col>
    </Row>;
    