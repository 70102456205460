import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { DealerLocatorComponent } from "./DealerLocator.component";
import { DealerLocatorProps, IDealerLocatorDispatchProps, IDealerLocatorProps, IDealerLocatorStateProps } from "./DealerLocator.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IDealerLocatorProps):IDealerLocatorStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IDealerLocatorProps):IDealerLocatorDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IDealerLocatorStateProps, dispatch:IDealerLocatorDispatchProps, props:IDealerLocatorProps):DealerLocatorProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const DealerLocator = withRouter(connect<IDealerLocatorStateProps, IDealerLocatorDispatchProps, IDealerLocatorProps, DealerLocatorProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(DealerLocatorComponent));