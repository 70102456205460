import { navTemplate } from 'theme/libs/config';
import { api } from '../api';
import { feedParameters } from '../FeedParameters';
import { ICatalogResponseData } from './productNav.types';

export const productNav = async () => {
    const feedParams = await feedParameters();
    const res = await getProductData(feedParams);
    const semanticData = res.body.root[0].domain[0].semantic.filter( (semData:any) => semData.semanticName === 'nav')[0];

    let data;
    if (semanticData.catalogs) {
        data = semanticData.catalogs[0];
    }
    return data as ICatalogResponseData | undefined;
} 

const getProductData = (feedParams:any) => 
    api.get('lemans/api/feed-info/masterFeed')
        .query(feedParams)
        .query({
            locale: 'en',
            templateName: navTemplate
        });
    
