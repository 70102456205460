import { connect  } from "react-redux";
import { ProductImageComponent  } from "./ProductImage.component";
import { IProductImageDispatchProps, IProductImageProps, IProductImageStateProps, ProductImageProps } from "./ProductImage.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IProductImageProps):IProductImageStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IProductImageProps):IProductImageDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IProductImageStateProps, dispatch:IProductImageDispatchProps, props:IProductImageProps):ProductImageProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const ProductImage = connect<IProductImageStateProps, IProductImageDispatchProps, IProductImageProps, ProductImageProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ProductImageComponent);