import { categoryTemplate } from 'theme/libs/config';
import { api } from '../api';
import { feedParameters } from '../FeedParameters';
import { ICategoryResponseData, IResponseData } from './category.types';

export const category = async (categoryId:number) => {
    const feedParams = await feedParameters();
    const res = await getCategory(categoryId, feedParams);

    const body = res.body.root[0].domain[0].semantic.filter( semData => semData.semanticName === 'body')[0];
    let data = {} as ICategoryResponseData;

    if (body.categories) {
        data =  body.categories[0];
    }

    return data;
} 

const getCategory = (categoryId:number, feedParams:any):IResponseData => 
    api.get('lemans/api/feed-info/masterFeed')
        .query(feedParams)
        .query({
            categoryId,
            locale: 'en',
            templateName: categoryTemplate
        });
    
