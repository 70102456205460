import { IDealerData } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/OnlineDealer/onlineDealer.types';

// What gets passed into the component from the parent as attributes
export interface IDealerLocatorMapProps {
    apiKey: string;
    dealerList?: IDealerData[];
    defaultLat?: number;
    defaultLng?: number;
    dotUri?: string;
    featuredDotUri?: string;
    featuredMarkerDir?: string;
    featuredMarkerImageName?: string;
    markerDir?: string;
    offPageDealerList?: IDealerData[];
    selectedDealerIndex?: number;
    theme?: mapTheme;
}

// What gets returned from the first connect function (mapStateToProps)
export interface IDealerLocatorMapStateProps {

}

// What gets returned from the second connect function (mapDispatchToProps)
export interface IDealerLocatorMapDispatchProps {

}

// What gets added in the third connect function (mergeProps)
export interface IDealerLocatorMapMergeProps {

}

// The props that finally get passed to the component
export type DealerLocatorMapProps = IDealerLocatorMapStateProps & IDealerLocatorMapDispatchProps & IDealerLocatorMapMergeProps & IDealerLocatorMapProps;

export enum mapTheme {
    Standard,
    Silver,
    Retro,
    Dark,
    Night,
    Aubergine
}