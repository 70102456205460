import { connect  } from 'react-redux';

import { TilesGridComponent  } from './TilesGrid.component';
import { ITilesGridDispatchProps, ITilesGridProps, ITilesGridStateProps , TilesGridProps } from './TilesGrid.types';

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:ITilesGridProps):ITilesGridStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:ITilesGridProps):ITilesGridDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:ITilesGridStateProps, dispatch:ITilesGridDispatchProps, props:ITilesGridProps):TilesGridProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const TilesGrid = connect<ITilesGridStateProps, ITilesGridDispatchProps, ITilesGridProps, TilesGridProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(TilesGridComponent);