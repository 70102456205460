import * as React from "react";
import { NoteListProps } from './NoteList.types';

export const NoteList = (props: NoteListProps) => 
    <div className={'product-features-note--div'}>
    { props.data.map( item => 
        <p key={item.sequence} >
            <span>Note:</span> &nbsp;{item.feature}
        </p>
    )}
    </div>;