import { IEmbeddedVideoProps } from './EmbeddedVideo.types';

/** Returns the index of the query parameter characters in the media URL string */
export const getQueryParamIndex = (props:IEmbeddedVideoProps) => props.mediaUrl.indexOf('&');

/** Returns a boolean that indicates if query parameter characters exist in the media URL string */
export const hasQueryParams = (props:IEmbeddedVideoProps) => getQueryParamIndex(props) !== -1;

/** Constructs and returns the source URL for the embedded material using component properties */
export function getEmbeddedVideoUrl(props:IEmbeddedVideoProps) {
    const domain = props.mediaUrl.split('-')[0];
    const indexOfQueryParams = hasQueryParams(props) ? getQueryParamIndex(props) : undefined;
    const relativePath = props.mediaUrl.substring(props.mediaUrl.indexOf('-') + 1, indexOfQueryParams);
    const autoplay = props.autoplay ? '&autoplay=1' : '';
    
    return `https://www.${domain}.com/embed/${relativePath}?wmode=transparent&html5=1${autoplay}`;
}