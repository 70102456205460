import { connect  } from 'react-redux';
import { GenericStaticPageComponent  } from './GenericStaticPage.component';
import { GenericStaticPageProps, IGenericStaticPageDispatchProps, IGenericStaticPageProps, IGenericStaticPageStateProps  } from './GenericStaticPage.types';

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IGenericStaticPageProps):IGenericStaticPageStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IGenericStaticPageProps):IGenericStaticPageDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IGenericStaticPageStateProps, dispatch:IGenericStaticPageDispatchProps, props:IGenericStaticPageProps):GenericStaticPageProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const GenericStaticPage = connect<IGenericStaticPageStateProps, IGenericStaticPageDispatchProps, IGenericStaticPageProps, GenericStaticPageProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(GenericStaticPageComponent);