import { Layout } from 'antd';
import { ReduxContainer } from 'lemans-brandsites-common/components/ReduxContainer';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Footer } from 'theme/components/Footer';
import { Header } from 'theme/components/Header';
import { gAnalyticsId } from 'theme/libs/config';
import { routes } from 'theme/libs/routes';
import 'theme/libs/Styles/App/App.style.less';

export const App = (props:any) => 
    <ReduxContainer analyticsId={gAnalyticsId}>
        <div className="App">
            <Layout>
                <Layout.Header>
                   <Header />
                </Layout.Header>
                <Layout.Content>
                    <Switch>
                    { routes.map( (route:any) => 
                        <Route exact={route.exact || false} key={route.route || '404'} path={route.route} component={route.component} />
                    )}
                    </Switch>
                </Layout.Content>
                <Layout.Footer>
                    <Footer />
                </Layout.Footer>
            </Layout>
        </div>
    </ReduxContainer>;