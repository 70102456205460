import { switchOn } from "atp-pointfree";
import { localSiteUrl, prodSiteUrl, stageSiteUrl } from 'theme/libs/config';
import { getConfig } from './GetConfig';
import { getEnvironment } from './GetEnvironment';

export const apiConfig = getConfig(getEnvironment(window.location.hostname), {    
    host: env => switchOn(env.env, {        
        default: () => localSiteUrl,
        prod: () => prodSiteUrl,
        ready: () => `https://ready.amstires.com`, 
        stage: () => stageSiteUrl,
    }),
    restBase: "/wp-json/",
});