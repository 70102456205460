import { SearchOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { productNav } from 'lemans-brandsites-common/libs/ProductNav';
import * as React from "react";
import { Link } from 'react-router-dom';
import { default as brandLogoDesktop, default as brandLogoMobile, default as brandLogoTablet } from '../../media/images/ams_header_logo.png';
import hamburger from '../../media/images/mobile_hamburger_icon.png';
import { MobileMenu } from '../MobileMenu';
import { ProductMenu } from '../ProductMenu';
import './Header.styles.less';
import { HeaderProps } from "./Header.types";

export const HeaderComponent = (props:HeaderProps) => {
    const [state, setState] = React.useState({
        productData: undefined as any,
        showMobile: false
    });

    const getData = async () => {
        try {
            const data = await productNav();
            setState({...state, productData: data});
        }
        catch {
            // Something
        }
    }

    React.useEffect( () => {
        getData();
    }, []);

    const handleCloseMobile = () => setState({...state, showMobile: false});
    const handleMobileIconClick = () => setState({...state, showMobile: !state.showMobile});

    const searchToggle = (e:any) => {
        e.preventDefault();       
        const searchForm : any = document.getElementById("searchForm");
        const searchInput : any = document.getElementById("searchBox");

        if (searchForm.style.display === "none") {
            searchForm.style.display = "block";
            searchInput.focus();
        } else {
            searchForm.style.display = "none";
        }
    }

    const searchSubmitHandler = (e:any) => {
        e.preventDefault();

        const searchForm : any = document.getElementById("searchForm");
        const searchInput : any = document.getElementById("searchBox");

        if(searchInput.value) {
            // modify the url
            props.history.push(`/search?q=${searchInput.value}`);
            // clear the input
            searchInput.value = "";
            // hide the form
            searchForm.style.display = "none";
        }
    }
    
    return (
        <nav>
            <Row justify="space-around" align="middle" id="menuRow1">
                <Col xs={0} sm={0} md={6} >
                    <Link className="site-logo" to="/" rel="home">
                        <img className="logo"src={brandLogoDesktop}/>
                    </Link>
                </Col>
                <Col xs={0} sm={4} md={0} >
                    <Link className="site-logo" to="/" rel="home">
                        <img className="logo"src={brandLogoTablet}/>
                    </Link>
                </Col>
                <Col xs={18} sm={0} md={0} >
                    <Link className="site-logo" to="/" rel="home">
                        <img className="logo"src={brandLogoMobile}/>
                    </Link>
                </Col>
                <Col xs={0} sm={18} md={14}>
                    <ProductMenu data={state.productData} />
                </Col>
                <Col sm={1} md={2} xs={0}>                    
                    <div id="searchButton">
                        <a href="#" onClick={searchToggle}><SearchOutlined style={{color:'white'}}/>{/*<Icon type="search" />*/}</a>                            
                    </div>                        
                </Col>
                <Col sm={0} md={0} xs={4}>                    
                    <div id="mobileMenu">
                        <a onClick={handleMobileIconClick}><img src={hamburger} /></a>                            
                    </div>                        
                </Col>
            </Row>
            <Col span={18} push={3} pull={3} className="searchFormContainer">
                <div id="searchForm" style={{display: 'none'}}>
                    <form onSubmit={searchSubmitHandler}>
                        <input type="text" className="form-control" name="q" id="searchBox" placeholder="Search.." autoComplete="off" />
                    </form>
                </div>
            </Col>
            <MobileMenu visible={state.showMobile} onClose={handleCloseMobile} data={state.productData} />
        </nav>        
    );
}
    