import { connect  } from 'react-redux';

import { RelatedProductsComponent  } from './RelatedProducts.component';
import { IRelatedProductsDispatchProps, IRelatedProductsProps, IRelatedProductsStateProps , RelatedProductsProps } from './RelatedProducts.types';

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IRelatedProductsProps):IRelatedProductsStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IRelatedProductsProps):IRelatedProductsDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IRelatedProductsStateProps, dispatch:IRelatedProductsDispatchProps, props:IRelatedProductsProps):RelatedProductsProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const RelatedProducts = connect<IRelatedProductsStateProps, IRelatedProductsDispatchProps, IRelatedProductsProps, RelatedProductsProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(RelatedProductsComponent);