import { switchOn } from 'atp-pointfree';
import { Environment } from "../GetEnvironment/getEnvironment.types";
import { Config, ConfigGenerator, ConfigRaw, ConfigValueRaw } from './getConfig.types';

const processConfigValue = (value:ConfigValueRaw, env:Environment) => switchOn(typeof value, {
    default: () => value,
    function: () => (value as ConfigGenerator)(env),
    object: () => getConfig(env, value as ConfigRaw),
});

export const getConfig = (env:Environment, config:ConfigRaw):Config => Object.keys(config).reduce(
    (combined:Config, key:string) => Object.assign({}, combined, {
        [key]: processConfigValue(config[key], env)
    }),
    {}
);
