import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { MobileMenuComponent } from "./MobileMenu.component";
import { IMobileMenuDispatchProps, IMobileMenuProps, IMobileMenuStateProps, MobileMenuProps } from "./MobileMenu.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IMobileMenuProps):IMobileMenuStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IMobileMenuProps):IMobileMenuDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IMobileMenuStateProps, dispatch:IMobileMenuDispatchProps, props:IMobileMenuProps):MobileMenuProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const MobileMenu = withRouter(connect<IMobileMenuStateProps, IMobileMenuDispatchProps, IMobileMenuProps, MobileMenuProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(MobileMenuComponent));