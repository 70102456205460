// Place for defining stuff which needs to be passed to shared/common components
import { default as brandLogo } from '../media/images/ams_spinner.png';

export const gAnalyticsId = 'UA-18714792-1';
export const gaMeasurementId   = 'G-TLVGL9W5ER';
export const localSiteUrl = `http://local.amstires.com`;
export const stageSiteUrl = `https://stage.amstires.com`;
export const prodSiteUrl = `https://www.amstires.com`;
export const stageHostUrl = `stage.amstires.com`;
export const prodHostUrl = `www.amstires.com`;
export const navTemplate = 'nav2020';
export const spinnerLogo = brandLogo;
export const categoryTemplate = 'Category2020';
export const prodTemplate = 'Product2020';
export const prodGrpTemplate = 'ProductGroup2020';
export const siteKey = '6LchMMwZAAAAAF4cL6U4vgX7RjT-ik_S6S5M-qXL';