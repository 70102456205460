import { Col, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import * as React from "react";
import { Link } from 'react-router-dom';
import './FourOhFour.styles.less';
import { FourOhFourProps } from "./FourOhFour.types";

export const FourOhFourComponent = (props:FourOhFourProps) => {
    const [state, setState] = React.useState({        
        bannerImage: undefined,
        isLoading: true,
        pageLinks: []
    });

    const getPageData = async () => {
        try {
            const data = await page('four-oh-four');

            setState({
                ...state,                
                bannerImage: data.acf.banner_image,                
                isLoading: false,
                pageLinks: data.acf.page_links,
            })
        } 
        catch {
            setState({...state, isLoading: false});
        }
    }

    React.useEffect( () => {
        getPageData();
    }, []);

    const bannerComponent = () => {
        let output = <img />;

        if (state.bannerImage) {
            output = <img src={`${apiConfig.host}${state.bannerImage}`} />
        }
        
        return output;
    };

    return (
        <>
        { state.isLoading &&
            <Spinner />
        }
        { !state.isLoading && 
            <Page
                description={'This page no longer exists.'}
                robots={{index: false, follow: false}}
                title={'AMS Tires'}
            >                
                <>
                    <div className="pageBanner">
                        {bannerComponent()}
                    </div>
                    <Row 
                        align={'bottom'} 
                        justify="space-around"
                        className={'fourohfour_content__wrapper'}
                    >
                        <Col xs={21} offset={3}><h1>Looks Like You blew a tire.</h1></Col>
                        <Col xs={21} offset={3}><h3>The link is broken or the page has been removed. Try one of these pages instead.</h3></Col>
                        <Col xs={21} offset={3} className="page_links__wrapper" key="page_links__wrapper">
                        {
                            state.pageLinks && typeof state.pageLinks !== 'undefined' && state.pageLinks.map( (pageLink:any, index:any) =>                                 
                                <Col xs={24} key={index}><Link to={pageLink.label_link} key={pageLink.label_text} className="page_links">{pageLink.label_text}</Link></Col>
                            )
                        }
                        </Col>
                    </Row>
                </>
            </Page>
        }
        </>
    );
}
    