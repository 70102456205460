import * as React from "react";
import { Link } from 'react-router-dom';
import './MobileMenu.styles.less';
import { IMobileMenuState, MobileMenuProps } from "./MobileMenu.types";

export const MobileMenuComponent = (props:MobileMenuProps) => {
    const [state, setState] = React.useState<IMobileMenuState>({
        showCategories: false
    });

    React.useEffect( () => {
        setState({showCategories: false});
    }, [props.visible])

    const handleProductClick = (e: any) => {
        e.preventDefault();

        setState({...state, showCategories: !state.showCategories});
    }

    const handleCloseIconClick = () => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const searchSubmitHandler = (e:any) => {
        e.preventDefault();

        // const searchForm : any = document.getElementById("searchForm");
        const searchInput : any = document.getElementById("searchBoxMobile");

        if(searchInput.value) {
            // modify the url
            props.history.push(`/search?q=${searchInput.value}`);
            // clear the input
            searchInput.value = "";

            handleCloseIconClick();
        }
    }

    return (
        <ul className={'mobile-menu-wrapper' + (props.visible === undefined || props.visible ? ' display-mobile-menu' : '')} key={'mobMenuWrapper'}>
            { props.data && props.data.categories.filter((item:any) => item.tag.toLowerCase() !== 'hidden').map( (category:any) => {
                return (
                    category.categories ?
                        <div key={category.categoryName}>
                        <li key={category.categoryName + 'li'}><a onClick={handleProductClick} key={category.categoryName + 'a'}>{category.categoryName}</a></li>
                        <ul key={category.categoryId} className={'mobile-dropdown-wrapper' + (state.showCategories ? ' display-dropdown' : '')}>
                            {category.categories.map((subCat:any) => 
                               <li key={subCat.categoryId}>
                                    <Link 
                                        onClick={handleCloseIconClick}
                                        to={`/category/${subCat.categoryId}`} 
                                        dangerouslySetInnerHTML={{__html: subCat.categoryName}} 
                                        key={subCat.categoryId + 'link'}
                                    />
                                </li>
                            
                            )}
                        </ul>
                        </div>
                    :
                        <li key={category.categoryId}><Link 
                            to={`/category/${category.categoryId}`} 
                            // key={category.categoryId}
                            dangerouslySetInnerHTML={{__html: category.categoryName}} 
                        /></li>
                )
                })
            }
            <li><Link to="/videos" onClick={handleCloseIconClick}>Videos</Link></li>
            <li><Link to="/about" onClick={handleCloseIconClick}>About</Link></li>
            <li><Link to="/dealer-locator" onClick={handleCloseIconClick}>Dealer Locator</Link></li>
            <li><form onSubmit={searchSubmitHandler}><input type="text" className="searchBoxMobile" name="q" id="searchBoxMobile" placeholder="Search..." autoComplete="off" /></form></li>
        </ul>
    );
}