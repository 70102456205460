import { GenericStaticPage } from 'lemans-brandsites-common/components/GenericStaticPage';
import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import { IPageResponseData } from 'lemans-brandsites-common/libs/GetPageFromWP/page.types';
import * as React from "react";
import './RightTire.styles.less';
import { RightTireProps } from "./RightTire.types";

export const RightTireComponent = (props:RightTireProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [pageData, setPageData] = React.useState<IPageResponseData | undefined>(undefined);

    const getData = async () => {
        setIsLoading(true);
        try {
            const pageRes = await page('tires-101');
            setPageData(pageRes);
        }
        finally {
            setIsLoading(false);
        }
    }

    React.useEffect( () => {
        getData()
    }, [])
    
    return (
        <>
        { isLoading &&
            <Spinner />
        }
        { !isLoading && 
            <Page
                description={'AMS - BUILT FOR THE DEMANDING.'}
                robots={{follow: true, index: true}}
                title={'AMS Tires - Tires 101'}
            >
                {pageData &&
                    <GenericStaticPage data={pageData} />
                }
            </Page>
        }
        </>
    );
}
    