import { merge } from "atp-pointfree";
import * as React from "react";
import 'theme/components/ComponentDemo/ComponentDemo.config';
import { components } from 'theme/components/ComponentDemo/ComponentDemo.config';

const componentSorter = ((a:any, b:any) => `${a.module}/${a.library}/${a.name}`.localeCompare(`${b.module}/${b.library}/${b.name}`));

export const config = components.sort(componentSorter).reduce((conf:any, component:any) => merge(conf, {
    [component.module]: {
        [component.library]: {
            [component.name]: component,
        }
    }
}), {});

export const propTableColumns = [{
    dataIndex: 'name',
    key: 'name',
    title: 'Name',
},{
    dataIndex: 'type',
    key: 'type',
    title: 'Type',
},{
    dataIndex: 'Description',
    key: 'description',
    render: (Description:any) => <Description />,
    title: 'Description',
}]

