import { connect  } from 'react-redux';

import { RelatedProductItemComponent  } from './RelatedProductItem.component';
import { IRelatedProductItemDispatchProps, IRelatedProductItemProps, IRelatedProductItemStateProps , RelatedProductItemProps } from './RelatedProductItem.types';

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IRelatedProductItemProps):IRelatedProductItemStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IRelatedProductItemProps):IRelatedProductItemDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IRelatedProductItemStateProps, dispatch:IRelatedProductItemDispatchProps, props:IRelatedProductItemProps):RelatedProductItemProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const RelatedProductItem = connect<IRelatedProductItemStateProps, IRelatedProductItemDispatchProps, IRelatedProductItemProps, RelatedProductItemProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(RelatedProductItemComponent);