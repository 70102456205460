import { connect  } from "react-redux";
import { HelpCenterComponent  } from "./HelpCenter.component";
import { HelpCenterProps, IHelpCenterDispatchProps, IHelpCenterProps, IHelpCenterStateProps } from "./HelpCenter.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IHelpCenterProps):IHelpCenterStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IHelpCenterProps):IHelpCenterDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IHelpCenterStateProps, dispatch:IHelpCenterDispatchProps, props:IHelpCenterProps):HelpCenterProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const HelpCenter = connect<IHelpCenterStateProps, IHelpCenterDispatchProps, IHelpCenterProps, HelpCenterProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(HelpCenterComponent);