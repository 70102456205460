import { Col, Row } from 'antd';
import * as React from "react";
import { Link } from 'react-router-dom';
import './ResultGrid.styles.less';
import { ResultGridProps } from "./ResultGrid.types";

export const ResultGridComponent = (props:ResultGridProps) => 
    <Row 
        align={'bottom'} 
        className={'result-grid-wrapper' + (props.className ? ` ${props.className}` : '')}
    >                                
    { props.items && props.items.map( item => 
        <Col xl={props.xlSize} lg={props.lgSize} md={props.mdSize} sm={props.smSize} xs={props.xsSize} key={item.key} className="resultGridCol">
            <Link
                to={item.linkUrl} 
                title={item.linkTitle}
            >
                <img src={item.imgSrc}/>
            </Link>
            <div className="result-grid-title">{item.itemTitle}</div>
        </Col>                  
    )}
    </Row>
    