import { App } from 'lemans-brandsites-common/components/App';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.less';
import registerServiceWorker from './registerServiceWorker';

const embedded = self !== top;

const start = (source:WindowProxy) => {
  ReactDOM.render(
    <App embedded={embedded} embedData={{source}}/>,
    document.getElementById('root') as HTMLElement
  );
  registerServiceWorker();
};

if(embedded) {
  let started = false;
  window.addEventListener('message', (e:MessageEvent) => {
    if(e.data === 'start' && !started) {
      start(e.source as WindowProxy);
      started = true;
      (e.source as WindowProxy).postMessage("started", "*");
    }
  });
} else {
  start(window);
}