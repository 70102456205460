import { Row } from 'antd';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import * as React from 'react';
import { RelatedProductItem } from '../RelatedProductItem';
import './RelatedProducts.styles.less';
import { IRelatedItemListingState, RelatedProductsProps } from './RelatedProducts.types';

export const RelatedProductsComponent = (props:RelatedProductsProps) => {
    const [state, setState] = React.useState<IRelatedItemListingState>({
        data: undefined,
        isLoading: true
    });

    React.useEffect( () => {
        setState({...state, isLoading: true});
        try {
            setState({
                ...state,
                data: props.data,
                isLoading: false,
            });
        }
        catch {
            setState({...state, isLoading: false});
        }
    }, [props.data]);

    return (
        <>
        { state.isLoading &&
            <Spinner />
        }
        { !state.isLoading && state.data &&  
            <Row 
                align={'bottom'} 
                className={'result-grid-wrapper'}
            > 
            {
                state.data.map((item:any) => {
                    return <RelatedProductItem data={item} key={item.relatedName}/>
                })   
            }
            </Row>          
        }
        </>
    );
}
    
    