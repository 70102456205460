import { ldlApi } from '../Api';
import { ICountryListResponse } from './country.types';

export const country = async (apiConfig:any) => {
    const res = await getCountries(apiConfig);

    return res;
}

const getCountries = (apiConfig: any):ICountryListResponse => 
    ldlApi(apiConfig).get('country')