import { Collapse } from 'antd';
import * as React from "react";
import './PartChart.styles.less';
import { PartChartProps } from "./PartChart.types";

const { Panel } = Collapse;

export const PartChartComponent = (props:PartChartProps) => { 
    const chartHeaderColsIncludingSize =   props.columns && props.columns.filter((col:any) => col.name.toLowerCase() === 'size'); 
    // const sizeSequenseNumber = props.columns && props.columns.filter((col:any) => col.name.toLowerCase() === 'size')[0].sequence || undefined;
    const sizeSequenseNumber = chartHeaderColsIncludingSize.length > 0 ? chartHeaderColsIncludingSize[0].sequence : undefined;

    const finalRes = {};
    if(sizeSequenseNumber) { 
        // const finalRes = {};
        props.data.forEach( row => {
            const size = row.columns.filter(field => field.sequence === sizeSequenseNumber)[0].value;
            if( typeof finalRes[size] === 'undefined') {
                finalRes[size] = []
            } 
            finalRes[size].push(row);            
        });
    }

    return (
        <>
        <div className={'part-chart-wrapper--div' + (props.className ? ` ${props.className}` : '')}>
            {finalRes !== undefined && Object.keys(finalRes).map((sizeVal:any, index:number) => {                    
                const header = 'SIZE ' + sizeVal;
                return <>                
                <Collapse defaultActiveKey={['1']} key={sizeVal} expandIconPosition="right" ghost className="part_chart_collapse">
                    <Panel header={header} key={index + 1}>
                        <table key={'partCHartTable'}>
                            <thead className={'part-chart-header--thead'}>
                                <tr>
                                    <th>{ props.partNumberHeader ? props.partNumberHeader : 'Part#'}</th>

                                    { props.columns.sort( (a, b) => a.sequence - b.sequence ).map( column => 
                                        <th key={column.sequence}>{column.name !== "" ? column.name : column.field}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody className={'part-chart-body--tbody'}>
                                {props.data.filter((item:any) => item.columns.some((el:any) => el.value === sizeVal)).map( row => {
                                return <tr key={row.partNumber}>
                                    <td>{row.partNumber}</td>
                                    { row.columns.sort( (a, b) => a.sequence - b.sequence ).map( field => 
                                        <td key={field.sequence}>{field.field.toLowerCase() === 'retailprice' ? '$' + field.value : field.value}</td>
                                    )}
                                </tr>
                                }
                                )}
                            </tbody>
                        </table>
                    </Panel>
                </Collapse>
                <br />
                </>
                }
            )}
            {Object.keys(finalRes).length === 0 &&
                <table style={{margin:"20px 0"}}>
                    <thead className={'part-chart-header--thead'}>
                        <tr>
                            <th>{ props.partNumberHeader ? props.partNumberHeader : 'Part#'}</th>

                            { props.columns.sort( (a, b) => a.sequence - b.sequence ).map( column => 
                                <th key={column.sequence}>{column.name !== "" ? column.name : column.field}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody className={'part-chart-body--tbody'}>
                        {props.data.map( row => {
                        return <tr key={row.partNumber}>
                            <td key={row.partNumber}>{row.partNumber}</td>
                            { row.columns.sort( (a, b) => a.sequence - b.sequence ).map( field => 
                                <td key={field.sequence}>{field.field.toLowerCase() === 'retailprice' ? '$' + field.value : field.value}</td>
                            )}
                        </tr>
                        }
                        )}
                    </tbody>
                </table>
            }
        </div>
        </>
    );
}
    