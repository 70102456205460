import { Col, Row } from 'antd';
import { DealerLocatorInputGroup } from 'lemans-brandsites-common/components/DealerLocatorInputGroup';
import { DealerLocatorInputParams } from 'lemans-brandsites-common/components/DealerLocatorInputGroup/DealerLocatorInputGroup.types';
import { DealerLocatorMap } from 'lemans-brandsites-common/components/DealerLocatorMap';
import { mapTheme } from 'lemans-brandsites-common/components/DealerLocatorMap/DealerLocatorMap.types';
import { Page } from 'lemans-brandsites-common/components/Page';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { dealer } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/Dealer';
import { IDealerData } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/OnlineDealer/onlineDealer.types';
import * as React from "react";
import { DealerListItem } from './DealerListItem';
import './DealerLocator.styles.less';
import { DealerLocatorProps } from "./DealerLocator.types";

export const DealerLocatorComponent = (props:DealerLocatorProps) => {
    const showOnlineDealers = () => props.history.push('/online-dealers');
    const [state, setState] = React.useState({
        activeDealerList: undefined as IDealerData[] | undefined,
        dealerList: undefined as IDealerData[] | undefined,
        isLoadingResults: false,
        offPageDealerList: undefined as IDealerData[] | undefined,
        selectedDealerIndex: undefined as number | undefined
    });

    // Set Google Maps API key
    const googleMapsAPI = process.env.NODE_ENV === 'production' ? 
        'AIzaSyCdeLY-V8NhXwRDvrEBligwGlIUAJWj2Qk' :     // Production domain locked key
        'AIzaSyCKLPoKMTbDZwBOEQuzayYjEUUUZ2hDpFg';      // General development key

    const mapComponent = 
        <DealerLocatorMap 
            apiKey={googleMapsAPI}
            theme={mapTheme.Dark}
            dealerList={state.dealerList}
            selectedDealerIndex={state.selectedDealerIndex}
            featuredMarkerDir={'//assets-static.lemansnet.com/common/img/dealerLocator/ams_markers/'}
            featuredMarkerImageName={'dlr_locator_feature.png'}
        />;

    /** onSearch event handler for the dealer locator input group */
    const onSearch = async (params: DealerLocatorInputParams) => {
        setState({
            ...state, 
            activeDealerList: undefined, 
            dealerList: undefined, 
            isLoadingResults: true, 
            offPageDealerList: undefined,
            selectedDealerIndex: undefined 
        });

        try {
            const data = await dealer(apiConfig, params);

            if (!Array.isArray(data)) {
                throw new Error('An error was returned from the Dealer Locator Service.');
            }

            const activeDealerList = data.length > 5 ? data.slice(0,5) : data;
            const offPageDealerList = data.length > 5 ? data.slice(5) : undefined;
    
            setState({
                ...state,
                activeDealerList, 
                dealerList: data, 
                isLoadingResults: false,
                offPageDealerList,
                selectedDealerIndex: undefined
            });
        }
        catch {
            // Handle error state?
        }
    }

    /** Updates selected index on dealer header click */
    const onItemClick = (index:number) => {
        setState({...state, selectedDealerIndex: index});
    }

    const rightComponent = 
        <div className={'dealer-locator-wrapper--div'}>
            <h1>Dealer Search</h1>
            <DealerLocatorInputGroup 
                apiConfig={apiConfig} 
                onOnlineDealerClick={showOnlineDealers}
                className={{
                    button: {
                        onlineDealers: 'styled-button-wrapper--button',
                        search: 'styled-button-wrapper--button styled-button-alternate--button'
                    },
                    dropdown: 'styled-option-wrapper--div',
                    input: 'styled-text-box-wrapper--input',
                    radio: 'styled-radio-wrapper--radio',
                    select: 'styled-select-wrapper--div',
                }}
                onSearch={onSearch}
            />
            <div className={'dealer-locator-results--div'}>
            { state.dealerList && state.dealerList.map( (item, index) =>
                <DealerListItem 
                    dealerData={item}
                    index={index}
                    key={index}
                    onClick={onItemClick}
                />
            )}
            </div>
        </div>;

    return (
        <Page
            description={'Find a dealer near you!'}
            robots={{index: true, follow: true}}
            title={'AMS Tires - Dealer Locator'}
        >
            <Row 
                align={'top'} 
                className={'dl_map__container'}
            >
                <Col xs={{span:24, order:2}} lg={{span:12, order:1}}>{mapComponent}</Col>
                <Col xs={{span:24, order: 1}} lg={{span:12, order:2}}>{rightComponent}</Col>
            </Row>            
        </Page>
    );
}