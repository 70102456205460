import { connect  } from "react-redux";
import { ProductFeaturesComponent  } from "./ProductFeatures.component";
import { IProductFeaturesDispatchProps, IProductFeaturesProps, IProductFeaturesStateProps, ProductFeaturesProps } from "./ProductFeatures.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IProductFeaturesProps):IProductFeaturesStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IProductFeaturesProps):IProductFeaturesDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IProductFeaturesStateProps, dispatch:IProductFeaturesDispatchProps, props:IProductFeaturesProps):ProductFeaturesProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const ProductFeatures = connect<IProductFeaturesStateProps, IProductFeaturesDispatchProps, IProductFeaturesProps, ProductFeaturesProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ProductFeaturesComponent);