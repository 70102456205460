import { connect } from "react-redux";
import { WheelMaintenanceComponent } from "./WheelMaintenance.component";
import { IWheelMaintenanceDispatchProps, IWheelMaintenanceProps, IWheelMaintenanceStateProps, WheelMaintenanceProps } from "./WheelMaintenance.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IWheelMaintenanceProps):IWheelMaintenanceStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IWheelMaintenanceProps):IWheelMaintenanceDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IWheelMaintenanceStateProps, dispatch:IWheelMaintenanceDispatchProps, props:IWheelMaintenanceProps):WheelMaintenanceProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const WheelMaintenance = connect<IWheelMaintenanceStateProps, IWheelMaintenanceDispatchProps, IWheelMaintenanceProps, WheelMaintenanceProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(WheelMaintenanceComponent);