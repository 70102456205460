import { FilterOutlined } from '@ant-design/icons';
import { Col, Drawer, Row } from 'antd';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { imageUrl } from 'lemans-brandsites-common/libs/imageUrl';
import * as React from "react";
import { Link } from 'react-router-dom';
import { SearchFacetFilters } from '../SearchFacetFilters';
import { SelectedFilters } from '../SelectedFilters';
import './SearchResults.styles.less';
import { SearchResultsProps } from "./SearchResults.types";

export const SearchResultsComponent = (props:SearchResultsProps) => {
    const [isDrawerVisible, setIsDrawerVisible] = React.useState(false);

     /** Returns the URL for the product group */
    const getProductGroupUrl = (productId:number, productGroupId:number) => {
        return `/products/${productId}/productGroup/${productGroupId}`;
    }

    const onSearch = async () => {
        if(props.onSearch) {
            props.onSearch();
        }
    }

    const showDetailedFilters = () =>
        setIsDrawerVisible(true);
    const onCloseDrawer = () => 
        setIsDrawerVisible(false);

    // TODO: figure out why the opened drawer is getting closed after applying the filters

    const getDrawer = () =>
        <Drawer
            placement='left'
            closable={true}
            onClose={onCloseDrawer}
            visible={isDrawerVisible}
            className="narrowResultsDrawer"
        >
            <Row className="fitment-selected-filters--container" justify="space-around" align="middle">
                <Col span={24}>
                    <SelectedFilters facetHeader={props.mmyResults.header} onSearch={onSearch}/>
                </Col>
                <Col span={24}>
                    <SearchFacetFilters facetHeader={props.mmyResults.header} availableFacets={props.mmyResults.facets}/>
                </Col>            
            </Row>
        </Drawer>

    return(
        <>
        <Spinner isLoading={props.isLoading}>
        {props.mmyResults && props.mmyResults.header &&
            <Row className="fitment-selected-filters--container" justify="space-between" align="middle">
                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <SelectedFilters facetHeader={props.mmyResults.header} onSearch={onSearch} key="selected_filter__key"/>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} >
                    <div className="filterbutton" onClick={showDetailedFilters} key="styled_button__key"><FilterOutlined /> narrow your results</div>
                    {getDrawer()}
                </Col>
            </Row>
        }
        {props.mmyResults && props.mmyResults.hitlist && props.mmyResults.hitlist.productGroups &&
            <Row gutter={48} justify="start" align="bottom" className="search-results--container">                                
                {
                    props.mmyResults.hitlist.productGroups.map((response:any, index: number) => 
                        <Col xl={6} lg={6} md={6} sm={12} xs={12} key={`col-key-${index}`} className="resultGridCol">
                            <Link 
                                to={getProductGroupUrl(response.productId, response.id)} 
                                title={response.productGroupName}
                                key={response.id}
                            >
                                <img 
                                    key={`key${index}`} 
                                    src={imageUrl(response, {x:400, t: 'image/jpeg', o:'3dshadow'})}
                                    className={'product-search--img'}
                                />
                            </Link>
                            <div key={`productname${index}`} className="product-image--title">{response.productName}</div>
                        </Col>
                    )
                }
            </Row>              
        } 
        {props.mmyResults && props.mmyResults.hitlist && props.mmyResults.hitlist.productGroups.length === 0 &&
            <Row gutter={48} justify="start" align="bottom" className="search-results--container">
                <Col span={24}>
                    <div className="noResultsDiv">No results found: We were unable to find any search results using the selected filters.</div>
                </Col>
            </Row>
        }
        </Spinner>       
        </>
    );
}
    
    