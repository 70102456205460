import { Button, Col, Form, Input, Row } from 'antd';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { api } from 'lemans-brandsites-common/libs/api';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { sanitizeAndFormat } from 'lemans-brandsites-common/libs/formHelpers';
import { isHumanUser, useReCaptcha } from 'lemans-brandsites-common/libs/ReCaptcha';
import * as React from "react";
import { siteKey } from 'theme/libs/config';
import './HelpCenterForm.styles.less';
import { HelpCenterFormProps, IHelpCenterFormState } from "./HelpCenterForm.types";

export const HelpCenterFormComponent = (props:HelpCenterFormProps) => {
    // const siteKey = '6Lc5Lp8UAAAAAFsvjfBy_h2YNczjXqtyBswUj9bT';
    const {isLoading, setIsLoading} = useReCaptcha(siteKey);
    const [state, setState] = React.useState<IHelpCenterFormState>({
        fetching: true,
        message: undefined
    });

    const handleSubmit = async(values:any) => {
        const isValidUser = await validateUser();

        if (!isValidUser) {
            handleSubmissionError();
        } else {
            sanitizeAndFormatValues(values);
            submitToCorrespondence(values);
        } 
    };

    const sanitizeAndFormatValues = (values:any) => {
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                values[key] = sanitizeAndFormat(key, values[key]);
            }
        }
    }

    const validateUser = async() => {
        let output;
        setIsLoading(true);
        try {
            output = await isHumanUser(siteKey, 'contactUs', apiConfig);
        }
        catch {
            output = false;
        }
        setIsLoading(false);

        return output;
    }

    const submitToCorrespondence = async (values: any) => {
        const url = 'lemans/rest/domain/12/form/ams_cu/submit';
        setIsLoading(true);

        try {
            const response = await api.post(url, values, 'form');

            if (response.statusCode === 200) {
                setState({
                    ...state,
                    message: `<h2>Your Message Has Been Sent</h2>
                    <p>Thank you for contacting us. We have received your submission and will respond as soon as possible.</p>`
                });
            } else {
                handleSubmissionError();
            }
        }
        catch (err) {
            handleSubmissionError();
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleSubmissionError = () => setState({...state, message:
        `<h2>Server Error</h2>
        <p>We're sorry, there was a server error. Please try again later.</p>`
    });

    return (
        <Spinner isLoading={isLoading}>
        { state.message && 
            <div 
                className={'contact-form-message--div'}
                dangerouslySetInnerHTML={{__html: state.message}} 
            />
        }
        { !state.message &&
            <Form onFinish={handleSubmit} className={'contact-form-wrapper--form'} layout="vertical">
                <Row gutter={16}>
                    <Col lg={12}>
                        <Form.Item label='First Name' name='form_1_firstName' rules={[{ 
                                message: 'Required field',
                                required: true, 
                                type: 'string', 
                            }]} 
                            validateTrigger= {['onChange', 'onBlur']}
                        >
                            <Input id={'first-name'} maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item label='Last Name' name='form_2_lastName' rules={[{ 
                                message: 'Required field',
                                required: true, 
                                type: 'string', 
                            }]} 
                            validateTrigger= {['onChange', 'onBlur']}
                        >
                            <Input id={'last-name'} maxLength={30} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={18}>
                        <Form.Item label='Email Address' name='form_3_emailAddress' rules={[{ 
                                message: 'Not a valid email address',
                                required: true, 
                                type: 'email',
                                // warning: true,
                            }]} 
                            validateTrigger= {['onChange', 'onBlur']}
                        >
                            <Input type={'email'} id={'email'} />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item label='Zip Code' name='form_4_zip'>
                            <Input id={'zip'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={24}>
                        <Form.Item label='Comment' name='form_5_comments'>
                            <Input.TextArea rows={5} id={'comments'} />
                        </Form.Item>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <Form.Item>
                            <Button type={'primary'} htmlType={'submit'} className="contact_submit">Submit</Button>
                        </Form.Item>
                        <div>
                            <small>
                                This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy 
                                Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                            </small>
                        </div>
                    </Col>
                </Row>
            </Form>
        }
        </Spinner>
    );
}
    