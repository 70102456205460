import { api } from '../api';

export const solrParameters = async () => {
    const res = await api.get(`lemans/lss/settings`);

    return res.body as ISolrParams;
}

interface ISolrParams {
    domainId: number;
    catalogInstanceId: number;
}