import { connect  } from "react-redux";
import { EmbeddedVideoComponent  } from "./EmbeddedVideo.component";
import { EmbeddedVideoProps, IEmbeddedVideoDispatchProps, IEmbeddedVideoProps, IEmbeddedVideoStateProps } from "./EmbeddedVideo.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IEmbeddedVideoProps):IEmbeddedVideoStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IEmbeddedVideoProps):IEmbeddedVideoDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IEmbeddedVideoStateProps, dispatch:IEmbeddedVideoDispatchProps, props:IEmbeddedVideoProps):EmbeddedVideoProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const EmbeddedVideo = connect<IEmbeddedVideoStateProps, IEmbeddedVideoDispatchProps, IEmbeddedVideoProps, EmbeddedVideoProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EmbeddedVideoComponent);