import { connect  } from "react-redux";
import { PageComponent  } from "./Page.component";
import { IPageDispatchProps, IPageProps, IPageStateProps, PageProps } from "./Page.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IPageProps):IPageStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IPageProps):IPageDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IPageStateProps, dispatch:IPageDispatchProps, props:IPageProps):PageProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const Page = connect<IPageStateProps, IPageDispatchProps, IPageProps, PageProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PageComponent);