import { Col, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import { IPageResponseData } from 'lemans-brandsites-common/libs/GetPageFromWP/page.types';
import * as React from "react";
import { HelpCenterForm } from '../HelpCenterForm';
import './HelpCenter.styles.less';
import { HelpCenterProps } from "./HelpCenter.types";

export const HelpCenterComponent = (props:HelpCenterProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [pageData, setPageData] = React.useState<IPageResponseData | undefined>(undefined);

    const getData = async () => {
        setIsLoading(true);
        try {
            const pageRes = await page('contact');
            setPageData(pageRes);
        }
        catch (err) {
            // Do something?
        }
        finally {
            setIsLoading(false);
        }
    }

    React.useEffect( () => {
        getData()
    }, [])

    const bannerComponent = () => {
        let output = <img />;

        if (pageData &&  pageData.acf.banner_image) {
            output = <div className="pageBanner"><img src={`${apiConfig.host}${pageData.acf.banner_image}`} /></div>
        }
        
        return output;
    };

    const pageContent =
        <div className={'help-center-wrapper--div'}>
            <h2>Send Us A Message</h2>
            <HelpCenterForm />
        </div>

    return (
        <>
        { isLoading &&
            <Spinner />
        }
        { !isLoading && 
            <Page
                description={'AMS TIRES - Contact Us.'}
                robots={{follow: true, index: true}}
                title={'AMS Tires - Contact'}
            >
                <>
                    {bannerComponent()}
                    <Row 
                        align={'bottom'} 
                        justify="space-around"
                        className={'generic-page-wrapper--div'}
                    >
                        <Col xs={18}>{pageContent}</Col>
                    </Row>            
                </>
            </Page>
        }
        </>
    );
}
    