import * as React from 'react';

//  Take in an initial state object and returns an object with the same keys with getters and setters
export function getState<T extends {}>(initialState: T) {
    const output: StateObject<T> = {} as StateObject<T>;

    for (const key in initialState) {
        if (initialState.hasOwnProperty(key)) {
            const element = initialState[key];
            const [value, set] = React.useState(element);
            output[key] = {value, set};
        }
    }

    return output;
}

type StateObject<T> = {
    [P in keyof T]: {
        set: React.Dispatch<T[Extract<keyof T, string>]>, 
        value: T[P]
    }
}