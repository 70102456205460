import { prodGrpTemplate } from 'theme/libs/config';
import { api } from '../api';
import { feedParameters } from '../FeedParameters';
import { IProductGroupResponseData, IResponseData } from '../Product/product.types';

export const productIdFromProductGroup = async (productGroupId:number, template?:string) => {
    const product = await (productGroup(productGroupId));
    return product.productId;
}

export const productGroup = async (productGroupId:number, template?:string) => {
    const feedParams = await feedParameters();
    const res = await getProductGroup(productGroupId, feedParams, template);
    const body = res.body.root[0].domain[0].semantic.filter( semData => semData.semanticName === 'body')[0];
    let data = {} as IProductGroupResponseData;

    if (body.productGroups) {
        data =  body.productGroups[0];
    }
    return data;
} 

const getProductGroup = (productGroupId:number, feedParams:any, template?:string):IResponseData => 
    api.get('lemans/api/feed-info/masterFeed')
        .query(feedParams)
        .query({
            locale: 'en',
            productGroupId,
            templateName: template ? template : prodGrpTemplate
        });
    
