import loadjs from 'loadjs';
import * as React from "react";
import { reCaptchaApi } from './reCaptchaApi';


export function useReCaptcha(siteKey: string) {
    const [isLoading, setIsLoading] = React.useState(true);
    const recaptchaUrl = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;

    React.useEffect( () => {
        if (!loadjs.isDefined('recaptcha')) {
            loadjs([recaptchaUrl], 'recaptcha');
            loadjs.ready('recaptcha', () => setIsLoading(false) );
        } else {
            setIsLoading(false);
        }
    }, []);

    return {isLoading, setIsLoading};
}

export async function isHumanUser(siteKey:string, action:string, apiConfig:any) {
    const token = await grecaptcha.execute(siteKey, {action});
    const data = await reCaptchaApi(apiConfig).get('recaptcha').query({token});

    return data.body.body.pass;
}