import * as React from "react";
import './Page.styles.less';
import { PageProps  } from "./Page.types";

export const PageComponent = (props:PageProps) => {
    document.title =  props.title;
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaRobots = document.querySelector('meta[name="robots"]');

    if (metaDescription) {
        metaDescription.setAttribute("content", props.description);
    }

    if (metaRobots) {
        const followText = props.robots.follow ? 'follow' : 'nofollow';
        const indexText = props.robots.index ? 'index' : 'noindex';

        metaRobots.setAttribute("content", `${indexText}, ${followText}`);
    }
    
    return (
        <div className={'page-content--div' + (props.className ? ` ${props.className}` : '')}>
            {React.Children.map(props.children, a => a)}
        </div>
    );
}