import { connect  } from "react-redux";
import { PartChartComponent  } from "./PartChart.component";
import { IPartChartDispatchProps, IPartChartProps, IPartChartStateProps, PartChartProps } from "./PartChart.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IPartChartProps):IPartChartStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IPartChartProps):IPartChartDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IPartChartStateProps, dispatch:IPartChartDispatchProps, props:IPartChartProps):PartChartProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const PartChart = connect<IPartChartStateProps, IPartChartDispatchProps, IPartChartProps, PartChartProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PartChartComponent);