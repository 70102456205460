import { connect } from "react-redux";
import { TireSafetyComponent } from "./TireSafety.component";
import { ITireSafetyDispatchProps, ITireSafetyProps, ITireSafetyStateProps, TireSafetyProps } from "./TireSafety.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:ITireSafetyProps):ITireSafetyStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:ITireSafetyProps):ITireSafetyDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:ITireSafetyStateProps, dispatch:ITireSafetyDispatchProps, props:ITireSafetyProps):TireSafetyProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const TireSafety = connect<ITireSafetyStateProps, ITireSafetyDispatchProps, ITireSafetyProps, TireSafetyProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(TireSafetyComponent);