import { Col, Modal, Row } from 'antd';
import { EmbeddedVideo } from 'lemans-brandsites-common/components/EmbeddedVideo';
import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import * as React from "react";
import './VideoPage.styles.less';
import { IVideoPageState, VideoPageProps } from "./VideoPage.types";

export const VideoPageComponent = (props:VideoPageProps) => {
    const [state, setState] = React.useState<IVideoPageState>({        
        allVideos: undefined,
        bannerImage: undefined,
        isLoading: true,
        showVideo: false,
        videoSrc: undefined
    });

    const getPageData = async () => {
        try {
            const data = await page('videos');

            setState({
                ...state,
                allVideos: data.acf.videos,
                bannerImage: data.acf.banner_image,                
                isLoading: false,
            })
        } 
        catch {
            setState({...state, isLoading: false});
        }
    }

    React.useEffect( () => {
        getPageData();
    }, []);

    const pageBannerComponent = () => {
        let output = <img />;

        if (state.bannerImage) {
            output = <img src={`${apiConfig.host}${state.bannerImage}`} />
        }
        
        return output;
    };

    const modalShowHandler = (src: string) => () => {
        setState({
            ...state,
            showVideo: true,
            videoSrc: src
        });
    } 

    const onModalCancel = () => {
        setState({
            ...state,
            showVideo: false,
            videoSrc: undefined
        })
    }

    const allVideos =
        <div className={'video-page-wrapper--div'}>
            <Modal 
                visible={state.showVideo} 
                centered={true} 
                footer={null}
                onCancel={onModalCancel}
                closable={true}
                className={'video-page-modal--div'}
                width={'70vw'}
            >
            { state.videoSrc &&
                <EmbeddedVideo mediaUrl={state.videoSrc} autoplay/>
            }
            </Modal>

            <Row
                align={'bottom'} 
                className={'tiles-grid-wrapper'}
            >                                
            { state.allVideos && state.allVideos.map( (item, index) =>
                <Col md={12} sm={24} key={index}>
                    <img 
                        src={`${apiConfig.host}${item.banner_image}`}
                        onClick={modalShowHandler(item.video_src)}
                        className={'video-page-link--img'}
                    />
                </Col>                  
            )}
            </Row>
        </div>;

    return (
        <>
        { state.isLoading &&
            <Spinner />
        }
        { !state.isLoading &&
            <Page 
                description={'AMS - BUILT FOR THE DEMANDING.'}
                robots={{follow: true, index: true}}
                title={'AMS Tires - Videos'}
            >
                <div className="pageBanner">
                    {pageBannerComponent()}
                </div>
                {allVideos}
            </Page>
        }
        </>
    ); 
}