import { ComponentDemo } from 'lemans-brandsites-common/components/ComponentDemo';
import { asPage } from 'lemans-brandsites-common/libs/asPage';
import { About } from 'theme/components/About';
import { DealerLocator } from 'theme/components/DealerLocator';
import { FeaturedTire } from 'theme/components/FeaturedTire';
import { FourOhFour } from 'theme/components/FourOhFour';
import { HelpCenter } from 'theme/components/HelpCenter';
import { HomePage } from 'theme/components/HomePage';
import { Legal } from 'theme/components/Legal';
import { OnlineDealers } from 'theme/components/OnlineDealers';
import { PrivacyPolicy } from 'theme/components/PrivacyPolicy';
import { ProductDetails } from 'theme/components/ProductDetails';
import { IProductDetailsProps } from 'theme/components/ProductDetails/ProductDetails.types';
import { ProductListing } from 'theme/components/ProductListing';
import { IProductListingProps } from 'theme/components/ProductListing/ProductListing.types';
import { RightTire } from 'theme/components/RightTire';
import { Search } from 'theme/components/Search';
import { TireSafety } from 'theme/components/TireSafety';
import { VideoPage } from 'theme/components/VideoPage';
import { WheelMaintenance } from 'theme/components/WheelMaintenance';

export const routes = [
    {route: "/", exact: true, component: HomePage},
    {route: '/category/:categoryId', exact: true, component: asPage<IProductListingProps>(ProductListing)},
    {route: "/products/:productId", exact: true, component: asPage<IProductDetailsProps>(ProductDetails)},
    {route: "/products/:productId/productGroup/:productGroupId",  exact: true, component: asPage<IProductDetailsProps>(ProductDetails)},
    {route: '/search', component: Search},
    {route: '/videos', exact: true, component: VideoPage},
    {route: '/about', exact: true, component: About},
    {route: '/featured-tire', exact: true, component: FeaturedTire},
    {route: '/tires-101', exact: true, component: RightTire},
    {route: '/tire-safety', exact: true, component: TireSafety},
    {route: '/wheel-maintenance', exact: true, component: WheelMaintenance},
    {route: '/legal', exact: true, component: Legal},
    {route: '/privacy-policy', exact: true, component: PrivacyPolicy},
    {route: '/contact', exact: true, component: HelpCenter},
    {route: "/dealer-locator", exact: true, component: DealerLocator},    
    {route: '/online-dealers', exact: true, component: OnlineDealers},
    {route: "/demo", exact: true, component: ComponentDemo},    
    {component: FourOhFour}
];
