import basicReducer from 'basic-reducers';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import ReactGA from "react-ga4";
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { gaMeasurementId } from 'theme/libs/config';
import { IReduxContainerProps } from './ReduxContainer.types';

export const ReduxContainer = (props:IReduxContainerProps) => {
    const history = createBrowserHistory();

    // googleAnalytics.init(history, props.analyticsId);
    ReactGA.initialize(gaMeasurementId);

    const reducers = Object.assign({}, props.reducers, {
        basic: basicReducer,
        router: connectRouter(history)
    });

    const store = createStore(
        combineReducers(reducers),
        props.initialState,
        applyMiddleware(routerMiddleware(history), thunk)
    );

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <>{React.Children.map(props.children, a => a)}</>
            </ConnectedRouter>
        </Provider>
    );
}
