import { connect } from "react-redux";
import { PrivacyPolicyComponent } from "./PrivacyPolicy.component";
import { IPrivacyPolicyDispatchProps, IPrivacyPolicyProps, IPrivacyPolicyStateProps, PrivacyPolicyProps } from "./PrivacyPolicy.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IPrivacyPolicyProps):IPrivacyPolicyStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IPrivacyPolicyProps):IPrivacyPolicyDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IPrivacyPolicyStateProps, dispatch:IPrivacyPolicyDispatchProps, props:IPrivacyPolicyProps):PrivacyPolicyProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const PrivacyPolicy = connect<IPrivacyPolicyStateProps, IPrivacyPolicyDispatchProps, IPrivacyPolicyProps, PrivacyPolicyProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PrivacyPolicyComponent);