import * as React from "react";
import { FeatureListProps } from './FeatureList.types';

export const FeatureList = (props: FeatureListProps) => 
    <ul className={`product-features-bullet--ul`}>
        { props.data.map( item => 
            <li key={item.sequence}>
                {item.feature}
            </li>
        )}
    </ul>;