import { country } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/Country';
import { inputElements } from './DealerLocatorInputGroup.types';

export const classNameKeys = {
    button: {
        onlineDealers: 'onlineDealers',
        search: 'search'
    },
    dropdown: {
        country: 'country',
        range: 'range',
        state: 'state'
    },
    input: {
        city: 'city',
        zip: 'zip'
    },
    radio: {
        searchType: 'searchType'
    },
    select: {
        country: 'country',
        range: 'range',
        state: 'state'
    }
}

/** Combines default classes with classes specified in properties */
export const classNameHandlerCreator = (props: any) => (base:string, element?:inputElements, input?:string) => {
    let output = base;
    const propClass = props.className as any;

    if (propClass) {
        if (typeof propClass === 'string') {
            output += ` ${propClass}`;
        } else if (element && propClass[element]) {
            if (typeof propClass[element] === 'string') {
                output += ` ${propClass[element]}`;
            } else if (input && typeof propClass[element][input] === 'string') {
                output += ` ${propClass[element][input]}`;
            }
        }
    }
    
    return output;
} 

/** Use the dealer locator plugin to get the country list */
export const getCountryList = async (props:any, state:any) => {
    const data = await country(props.apiConfig);

    state.countryList.set(data.body);
}