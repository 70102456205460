import { api } from '../api';

export const feedParameters = async () => {
    const res = await api.get(`lemans/lfr/settings`);

    return res.body as ILfrParams;
}

interface ILfrParams {
    domainId: number;
    catalogInstanceId: number;
}