import { connect  } from "react-redux";
import { StyledCheckBoxComponent  } from "./StyledCheckBox.component";
import { IStyledCheckBoxDispatchProps, IStyledCheckBoxProps, IStyledCheckBoxStateProps, StyledCheckBoxProps } from "./StyledCheckBox.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IStyledCheckBoxProps):IStyledCheckBoxStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IStyledCheckBoxProps):IStyledCheckBoxDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IStyledCheckBoxStateProps, dispatch:IStyledCheckBoxDispatchProps, props:IStyledCheckBoxProps):StyledCheckBoxProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const StyledCheckBox = connect<IStyledCheckBoxStateProps, IStyledCheckBoxDispatchProps, IStyledCheckBoxProps, StyledCheckBoxProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(StyledCheckBoxComponent);