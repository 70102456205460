import { Col, Row } from 'antd';
import * as React from "react";
import './DealerListItem.styles.less';
import { DealerListItemProps } from "./DealerListItem.types";

export const DealerListItemComponent = (props:DealerListItemProps) => {
    const handleOnClick = () => {
        if (props.onClick) {
            props.onClick(props.index);
        }
    }

    return (
    <div className={'dealer-list-item-wrapper--div'}>
        <Row gutter={16}>
            <Col xl={16}>
                <h3 onClick={handleOnClick}>{props.index + 1}. {props.dealerData.dealerName}</h3>
                <p>
                    {props.dealerData.dealerAddressLine1}{props.dealerData.dealerAddressLine2 ? `, ${props.dealerData.dealerAddressLine2}` : ''}<br />
                    {props.dealerData.dealerAddressCity}, {props.dealerData.dealerAddressState} {props.dealerData.dealerAddressZipCode}<br />
                    PH: {props.dealerData.dealerPhoneNumber} <strong>&middot;</strong> {props.dealerData.distance} miles
                </p>
            </Col>
            <Col xl={8}>
                <div className={'dealer-list-item-links--div'}>
                { props.dealerData.dealerEmailAddress &&
                    <a href={`mailto:${props.dealerData.dealerEmailAddress}`}>Email</a>
                }
                { props.dealerData.dealerWebSiteUrl &&
                    <a href={props.dealerData.dealerWebSiteUrl} target={'_blank'}>Website</a>
                }
                </div>
            </Col>
        </Row>
    </div>
    );
}
    
    