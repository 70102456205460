import * as React from "react";
import { Link } from 'react-router-dom';
import { ProductDropdownMenu } from './ProductDropdownMenu';
import './ProductMenu.styles.less';
import { ProductMenuProps } from "./ProductMenu.types";

export const ProductMenuComponent = (props:ProductMenuProps) => {
    return <div className={'navigation-menu-wrapper'}>
        {props.data && 
            props.data.categories.filter((item:any) => item.tag.toLowerCase() !== 'hidden').map( (item:any) => {
                return (
                    item.categories ?                         
                        <div className={'navigation-dropdown-wrapper'} key={item.categoryName}>
                            <a>{item.categoryName}</a>
                            <ProductDropdownMenu data={item.categories} location={props.location} /> 
                        </div>
                    :
                        <Link 
                            to={`/category/${item.categoryId}`} 
                            key={item.categoryId}
                            dangerouslySetInnerHTML={{__html: item.categoryName}} 
                        />
                )
            })
        }
        <Link to="/videos">Videos</Link>
        <Link to="/about">About</Link>
        <Link to="/dealer-locator">Dealer Locator</Link>
    </div>}