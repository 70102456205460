import { Page } from 'lemans-brandsites-common/components/Page';
import { ResultGrid } from 'lemans-brandsites-common/components/ResultGrid';
import { IResultGridItem } from 'lemans-brandsites-common/components/ResultGrid/ResultGrid.types';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { category } from 'lemans-brandsites-common/libs/Category';
import { ICategoryResponseData } from 'lemans-brandsites-common/libs/Category/category.types';
import { imageUrl } from 'lemans-brandsites-common/libs/imageUrl';
import * as React from "react";
import './ProductListing.styles.less';
import { IProductListingState, ProductListingProps } from "./ProductListing.types";

export const ProductListingComponent = (props:ProductListingProps) => {
    const tags = {
        hidden: 'HIDDEN'
    }

    const [state, setState] = React.useState<IProductListingState>({
        categoryName: undefined,
        data: undefined,
        imageUrl: undefined,
        isLoading: true
    });

    const getCategoryItems = (data: ICategoryResponseData):IResultGridItem[] => data.categories!.filter( item => item.tag !== tags.hidden).map( item => {
        const imgSrc = {
            extension: item.extension,
            mediaUrl: item.mediaUrl, 
            prefixUrl: item.prefixUrl,
        };

        return {
            imgSrc: imageUrl(imgSrc, {x:400, t: 'image/jpeg'}),
            imgTitle: item.entityName, 
            itemTitle: item.entityName,
            key: item.entityName,
            linkUrl: `/category/${item.categoryId}`
        }
    });

    const getProductItems = (data: ICategoryResponseData) => data.products!.filter( item => item.tag !== tags.hidden).map( item => {
        const imgSrc = {
            extension: item.extension,
            mediaUrl: item.mediaUrl, 
            prefixUrl: item.prefixUrl,
        };

        const productGroup = item.productGroupId;

        return {
            imgSrc: imageUrl(imgSrc, {x:400, t: 'image/jpeg', o:'3dshadow'}),
            imgTitle: item.entityName, 
            itemTitle: item.entityName,
            key: item.entityName,
            linkUrl: `/products/${item.productId}/productGroup/${productGroup}`
        };
    });

    const getResultItems = (data: ICategoryResponseData) => data.categories ? getCategoryItems(data) : getProductItems(data);

    const getData = async () => {
        setState({...state, isLoading: true});
        try {
            const data = await category(props.categoryId);
            const items = getResultItems(data); 
            
            setState({
                ...state,
                categoryName: data.categoryName,
                data: items,
                isLoading: false,
            });
        }
        catch {
            setState({...state, isLoading: false});
        }
    }

    React.useEffect( () => {
        getData();
    }, [props.categoryId]);

    return (
        <>
        { state.isLoading &&
            <Spinner />
        }
        { !state.isLoading &&
            <Page
                description={'AMS - BUILT FOR THE DEMANDING.'}
                robots={{follow: true, index: true}}
                title={`AMS TIRES - ${state.categoryName}`}
            >
                { state.data &&
                    <ResultGrid items={state.data} xlSize={6} lgSize={8} mdSize={8} smSize={12} xsSize={12}/>
                }
            </Page>
        }
        </>
    );
}