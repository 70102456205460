import { connect } from "react-redux";
import { RightTireComponent } from "./RightTire.component";
import { IRightTireDispatchProps, IRightTireProps, IRightTireStateProps, RightTireProps } from "./RightTire.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IRightTireProps):IRightTireStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IRightTireProps):IRightTireDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IRightTireStateProps, dispatch:IRightTireDispatchProps, props:IRightTireProps):RightTireProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const RightTire = connect<IRightTireStateProps, IRightTireDispatchProps, IRightTireProps, RightTireProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(RightTireComponent);