import { Button, Col, Input, Radio, Row, Select } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ICountryList } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/Country/country.types';
import { getState } from 'lemans-brandsites-common/libs/getState';
import * as React from "react";
import { classNameHandlerCreator, classNameKeys, getCountryList } from './DealerLocatorInputGroup.helpers';
import './DealerLocatorInputGroup.styles.less';
import { DealerLocatorInputGroupProps, inputElements } from "./DealerLocatorInputGroup.types";
import { StateConfig } from './StateSelect.config';

export const DealerLocatorInputGroupComponent = (props:DealerLocatorInputGroupProps) => {
    // State
    const state = getState({
        city: '', 
        country: 'US',
        countryList: []  as ICountryList[], 
        range: 20, 
        searchType: 'zip', 
        state: '',
        zip: ''
    });

    // Get dynamic list of international countries with dealers
    React.useEffect( ()=> {
        getCountryList(props, state);
    }, []);

    // Event handlers
    const cityChangeHandler = (e:React.ChangeEvent<HTMLInputElement>) => { state.city.set(e.target.value) }
    const zipChangeHandler = (e:React.ChangeEvent<HTMLInputElement>) => { state.zip.set(e.target.value) }    
    const searchTypeChangeHandler = (e:RadioChangeEvent) => { 
        // Reset city, state, and zip
        state.city.set('');
        state.state.set('');
        state.zip.set('');

        // update search type state
        state.searchType.set(e.target.value) 
    }

    const stateChangeHandler = (value: React.ReactText) => { state.state.set(value) }
    const rangeChangeHandler = (value: React.ReactText) => { state.range.set(value) }

    /** Combines default classes with classes specified in properties */
    const classNameHandler = classNameHandlerCreator(props);

    /** Country select change handler.  Gets dealer results if country is not US */
    const countryChangeHandler = (value: React.ReactText) => {
        
        state.country.set(value);

        if (value !== 'US' && props.onSearch) {
            props.onSearch({country: value.toString()});
        }
    }

    /** Determines if inputs have been sufficiently filled out to search */
    const hasValidParams = () => {
        let isValid = false;

        if (state.searchType.value === 'zip' && state.zip.value !== '') {
            isValid = true;
        }
        else if (state.city.value !== '' && state.state.value !== '') {
            isValid = true;
        }

        return isValid;
    }

    const onSearch = () => {
        if (props.onSearch && hasValidParams()) {
            props.onSearch({
                city: state.city.value !== '' ? state.city.value : undefined,
                country: state.country.value,
                distance: state.range.value,
                state: state.state.value !== '' ? state.state.value : undefined,
                zipCode: state.zip.value !== '' ? state.zip.value : undefined
            });
       }
    }

    /** Trigger search if user presses enter/return while in an input element */
    const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSearch();
        }
    }

    const selectFilter = (input:string, option:any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    return (
        <Row 
            className={classNameHandler('dealer-locator-input-wrapper--div')} 
            gutter={16}
        >
            <Col xs={12} lg={8}>
                <Row>
                    <Select 
                        value={state.country.value} 
                        onChange={countryChangeHandler}
                        className={classNameHandler('dealer-locator-country-wrapper--div', inputElements.select, classNameKeys.select.country)}
                        dropdownClassName={classNameHandler('dealer-locator-country-dropdown--div', inputElements.dropdown, classNameKeys.dropdown.country)}
                        dropdownMatchSelectWidth={false}
                        showSearch
                        filterOption={selectFilter}
                        onInputKeyDown={onKeyUp}
                    >
                    { !state.countryList.value || state.countryList.value.length === 0 &&
                        <Select.Option value={'US'}>United States</Select.Option>
                    }
                    { state.countryList.value && state.countryList.value.length > 0 && state.countryList.value.map( item => 
                        <Select.Option key={item.sortOrder} value={item.countryCode}>{item.countryName}</Select.Option>
                    )}   
                    </Select>
                </Row>
                { state.country.value === 'US' && 
                <>
                { state.searchType.value === 'zip' &&
                    <Row>
                        <Input 
                            placeholder={'Zip Code'} 
                            value={state.zip.value} 
                            onChange={zipChangeHandler} 
                            className={classNameHandler('dealer-locator-zip--input', inputElements.input, classNameKeys.input.zip)}
                            onKeyUp={onKeyUp}
                        />
                    </Row>
                }
                { state.searchType.value === 'city' &&
                    <>
                        <Row>
                            <Input 
                                placeholder={'City'} 
                                value={state.city.value} 
                                onChange={cityChangeHandler} 
                                className={classNameHandler('dealer-locator-city--input', inputElements.input, classNameKeys.input.city)}
                                onKeyUp={onKeyUp}
                            />
                        </Row>
                        <Row>
                            <Select 
                                value={state.state.value === '' ? undefined : state.state.value} 
                                onChange={stateChangeHandler}
                                placeholder={'State'}
                                className={classNameHandler('dealer-locator-state-wrapper--div', inputElements.select, classNameKeys.select.state)}
                                dropdownClassName={classNameHandler('dealer-locator-state-dropdown--div', inputElements.dropdown, classNameKeys.dropdown.state)}
                                dropdownMatchSelectWidth={false}
                                showSearch
                                filterOption={selectFilter}
                                onInputKeyDown={onKeyUp}
                            >
                            { StateConfig.map( item => 
                                <Select.Option key={item.id} value={item.id}>{item.text}</Select.Option>
                            )}
                            </Select>
                        </Row>
                    </>
                }
                <Row gutter={8}>
                    <Col span={16}>
                        <Select 
                            value={state.range.value} 
                            onChange={rangeChangeHandler}
                            placeholder={'Range'}
                            className={classNameHandler('dealer-locator-range-wrapper--div', inputElements.select, classNameKeys.select.range)}
                            dropdownClassName={classNameHandler('dealer-locator-range-dropdown--div', inputElements.dropdown, classNameKeys.dropdown.range)}
                            dropdownMatchSelectWidth={false}
                            showSearch
                            filterOption={selectFilter}
                            onInputKeyDown={onKeyUp}
                        >
                            <Select.Option value={10}>10 Miles</Select.Option>
                            <Select.Option value={20}>20 Miles</Select.Option>
                            <Select.Option value={50}>50 Miles</Select.Option>
                            <Select.Option value={100}>100 Miles</Select.Option>
                        </Select>
                    </Col>
                    <Col span={8}>
                        <Button 
                            className={classNameHandler('dealer-locator-search--button', inputElements.button, classNameKeys.button.search)}
                            onClick={onSearch}
                        >
                            Go
                        </Button>
                    </Col>
                </Row>
                </>
                }
            </Col>
            { state.country.value === 'US' && 
            <>
                <Col xs={12} lg={8}>
                    <Row>
                        <Radio.Group 
                            value={state.searchType.value} 
                            onChange={searchTypeChangeHandler}
                            className={classNameHandler('dealer-locator-search-type--radio', inputElements.radio, classNameKeys.radio.searchType)}
                        >
                            <Radio value={'zip'}>Zip Code</Radio>
                            <Radio value={'city'}>City/State</Radio>
                        </Radio.Group>
                    </Row>
                </Col>
                <Col xs={12} lg={8}>
                { props.onOnlineDealerClick &&
                    <Row>
                        <Button 
                            className={classNameHandler('dealer-locator-input-dealers--button', inputElements.button, classNameKeys.button.onlineDealers)}
                            onClick={props.onOnlineDealerClick}
                        >
                            Online Dealers
                        </Button>
                    </Row>
                }
                </Col>
            </>
            }
        </Row>
    );
}