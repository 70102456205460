export function formatPhoneNumber(input:string) {
    return input.replace(/^1?(\d{3})(\d{3})(\d{4})$/,'$1-$2-$3');
}

export function formatDate(input:string) {
    const dateToFormat = new Date(input);
    if (!isNaN(dateToFormat.getTime())) {
        const convertedDate = dateToFormat.getUTCDate();
        // Months use 0 index
        const convertedMonth = dateToFormat.getMonth() + 1;
        return (convertedMonth < 10 ? '0' + convertedMonth : convertedMonth) + '/' + (convertedDate < 10 ? '0' + convertedDate : convertedDate) + '/' + dateToFormat.getFullYear();
    } else {
        return '';
    }
}

export function sanitizeText(input:string|undefined) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = input ? input : '';

    return tempDiv.textContent?  tempDiv.textContent.trim() : '';
}

export function sanitizeAndFormat(key:string, value:string|undefined) {
    let output = sanitizeText(value);

    if (key.match(/phone/i) && output) {
        output = formatPhoneNumber(output);
    }
    if (key.match(/birthdate/i) && output) {
        output = formatDate(output);
    }
    return output;
}