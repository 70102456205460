import { Carousel } from 'antd';
import { imageUrl } from 'lemans-brandsites-common/libs/imageUrl';
import * as React from "react";
import { Link } from 'react-router-dom';
import './ProductImage.styles.less';
import { ProductImageProps } from "./ProductImage.types";

export const ProductImageComponent = (props:ProductImageProps) => {
    // Constants used in the component
    const primaryImageSize = 600;
    const thumbnailImageSize = 100;

    /** Returns the URL for the product group */
    const getProductGroupUrl = (productGroupId:number) => {
        return `/products/${props.productId}/productGroup/${productGroupId}`;
    }

    return (
        <div className={'product-overview-wrapper--div'}>
            <Carousel 
                dots={props.selectedGroupMediaData.length > 1}
                className={'product-overview-carousel--div'}
                autoplay
            >
            { props.selectedGroupMediaData.map( data => 
                <img 
                    key={data.mediaId} 
                    src={imageUrl(data, {x:primaryImageSize, t: 'image/png', o:'3dshadow'})}
                    className={'product-overview-carousel--img'}
                />
            )}
            </Carousel>

            <div className={'product-overview-thumbnails--div'}>
            { props.productGroupData && props.productGroupData.map( productGroup => 
                <Link 
                    to={getProductGroupUrl(productGroup.productGroupId)} 
                    title={productGroup.productGroupName}
                    key={productGroup.productGroupId}
                >
                    <img src={imageUrl(productGroup, {x:thumbnailImageSize, t: 'image/jpeg'})} />
                </Link>
            )}
            </div>            
        </div>
    );
}
    