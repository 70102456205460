import { connect  } from "react-redux";
import { ProductDetailsComponent  } from "./ProductDetails.component";
import { IProductDetailsDispatchProps, IProductDetailsProps, IProductDetailsStateProps, ProductDetailsProps } from "./ProductDetails.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IProductDetailsProps):IProductDetailsStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IProductDetailsProps):IProductDetailsDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IProductDetailsStateProps, dispatch:IProductDetailsDispatchProps, props:IProductDetailsProps):ProductDetailsProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const ProductDetails = connect<IProductDetailsStateProps, IProductDetailsDispatchProps, IProductDetailsProps, ProductDetailsProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ProductDetailsComponent);