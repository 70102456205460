import { connect  } from "react-redux";
import { FooterComponent  } from "./Footer.component";
import { FooterProps, IFooterDispatchProps, IFooterProps, IFooterStateProps } from "./Footer.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IFooterProps):IFooterStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IFooterProps):IFooterDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IFooterStateProps, dispatch:IFooterDispatchProps, props:IFooterProps):FooterProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const Footer = connect<IFooterStateProps, IFooterDispatchProps, IFooterProps, FooterProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(FooterComponent);