import * as React from "react";
import { WarningListProps } from './WarningList.types';

export const WarningList = (props: WarningListProps) => 
    <div className={'product-features-warning--div'}>
    { props.data.map( item => 
        <p key={item.sequence} >
            <span>Safety Warning:</span> &nbsp;{item.feature}
        </p>
    )}
    </div>;