import { Button } from 'antd';
import * as React from "react";
import './StyledButton.styles.less';
import { StyledButtonProps } from "./StyledButton.types";

export const StyledButtonComponent = (props:StyledButtonProps) => {
    const {alternate, ...otherProps} = props;

    return (
        <Button 
            className={'styled-button-wrapper--button' + (props.alternate ? ' styled-button-alternate--button' : '')}
        {...otherProps}
        >
            {props.children}
        </Button>
    );
}
    
    